import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="monsters-barghest-couatl"></a><h2>Monsters</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">Barghest - Coautl</span>
    </h2>
    <h3><a id="barghest"></a>BARGHEST</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "16%"
          }}>
          </th>
          <th style={{
            "width": "38%"
          }}>Barghest
Medium <a href="monsterTypes.html#outsider" style={{
              "color": "black"
            }}>Outsider</a>
(<a href="monsterTypes.html#evil" style={{
              "color": "black"
            }}>Evil</a>, <a href="monsterTypes.html#extraplanar" style={{
              "color": "black"
            }}>Extraplanar</a>,
            <a href="monsterTypes.html#lawful" style={{
              "color": "black"
            }}>Lawful</a>,
            <a href="monsterTypes.html#shapechanger" style={{
              "color": "black"
            }}>Shapechanger</a>)</th>
          <th style={{
            "width": "4%"
          }}>
          </th>
          <th style={{
            "width": "42%"
          }}>Greater Barghest
Large <a href="monsterTypes.html#outsider" style={{
              "color": "black"
            }}>Outsider</a>
(<a href="monsterTypes.html#evil" style={{
              "color": "black"
            }}>Evil</a>, <a href="monsterTypes.html#extraplanar" style={{
              "color": "black"
            }}>Extraplanar</a>,
            <a href="monsterTypes.html#lawful" style={{
              "color": "black"
            }}>Lawful</a>,
            <a href="monsterTypes.html#shapechanger" style={{
              "color": "black"
            }}>Shapechanger</a>)</th>
        </tr>
        <tr className="odd-row">
          <td><b>Hit Dice:</b></td>
          <td>6d8+6 (33 hp)</td>
          <td></td>
          <td>9d8+27 (67 hp)</td>
        </tr>
        <tr>
          <td><b>Initiative:</b></td>
          <td>+6</td>
          <td></td>
          <td>+6</td>
        </tr>
        <tr className="odd-row">
          <td><b>Speed:</b></td>
          <td>30 ft. (6 squares)</td>
          <td></td>
          <td>40 ft. (8 squares)</td>
        </tr>
        <tr>
          <td><b>Armor Class:</b></td>
          <td>18 (+2 Dex, +6 natural), touch 12, flat-footed 16</td>
          <td></td>
          <td>20 (&ndash;1 size, +2 Dex, +9 natural), touch 11,
flat-footed
18</td>
        </tr>
        <tr className="odd-row">
          <td><b>Base Attack/Grapple:</b></td>
          <td>+6/+9 </td>
          <td></td>
          <td>+9/+18</td>
        </tr>
        <tr>
          <td><b>Attack:</b></td>
          <td>Bite +9 melee (1d6+3))</td>
          <td></td>
          <td>Bite +13 melee (1d8+5</td>
        </tr>
        <tr className="odd-row">
          <td><b>Full Attack:</b></td>
          <td>Bite +9 melee (1d6+3) and 2 claws +4 melee (1d4+1)</td>
          <td></td>
          <td>Bite +13 melee (1d8+5) and 2 claws +8 melee (1d6+2)</td>
        </tr>
        <tr>
          <td><b>Space/Reach:</b></td>
          <td>5 ft./5 ft.</td>
          <td></td>
          <td>10 ft./5 ft.</td>
        </tr>
        <tr className="odd-row">
          <td><b>Special Attacks:</b></td>
          <td>Spell-like abilities, feed</td>
          <td></td>
          <td>Spell-like abilities, feed</td>
        </tr>
        <tr>
          <td><b>Special Qualities:</b></td>
          <td><a href="monsterTypes.html#change-shape" style={{
              "color": "rgb(87, 158, 182)"
            }}>Change shape</a>, <a href="monsterTypes.html#DR" style={{
              "color": "rgb(87, 158, 182)"
            }}>damage
reduction</a> 5/magic, darkvision 60
ft., <a href="monsterTypes.html#scent" style={{
              "color": "rgb(87, 158, 182)"
            }}>scent</a></td>
          <td></td>
          <td><a href="monsterTypes.html#change-shape" style={{
              "color": "rgb(87, 158, 182)"
            }}>Change shape</a>, <a href="monsterTypes.html#DR" style={{
              "color": "rgb(87, 158, 182)"
            }}>damage
reduction</a> 10/magic, darkvision 60
ft., <a href="monsterTypes.html#scent" style={{
              "color": "rgb(87, 158, 182)"
            }}>scent</a></td>
        </tr>
        <tr className="odd-row">
          <td><b>Saves:</b></td>
          <td>Fort +6, Ref +7, Will +7</td>
          <td></td>
          <td>Fort +9, Ref +8, Will +10</td>
        </tr>
        <tr>
          <td><b>Abilities:</b></td>
          <td>Str 17, Dex 15, Con 13, Int 14, Wis 14, Cha 14</td>
          <td></td>
          <td>Str 20, Dex 15, Con 16, Int 18, Wis 18, Cha 18</td>
        </tr>
        <tr className="odd-row">
          <td><b>Skills:</b></td>
          <td><a href="skillsAll.html#bluff" style={{
              "color": "rgb(87, 158, 182)"
            }}>Bluff</a> +11, <a href="skillsAll.html#diplomacy" style={{
              "color": "rgb(87, 158, 182)"
            }}>Diplomacy</a>
+6, <a href="skillsAll.html#disguise" style={{
              "color": "rgb(87, 158, 182)"
            }}>Disguise</a>
+2 (+4 acting), <a href="skillsAll.html#hide" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hide </a>+11<sup>*</sup>, <a href="skillsAll.html#intimidate" style={{
              "color": "rgb(87, 158, 182)"
            }}>Intimidate</a>
+13, <a href="skillsAll.html#jump" style={{
              "color": "rgb(87, 158, 182)"
            }}>Jump</a>
+12, <a href="skillsAll.html#listen" style={{
              "color": "rgb(87, 158, 182)"
            }}>Listen</a>
+11, <a href="skillsAll.html#move-silently" style={{
              "color": "rgb(87, 158, 182)"
            }}>Move Silently</a> +10, <a href="skillsAll.html#search" style={{
              "color": "rgb(87, 158, 182)"
            }}>Search</a>
+11, <a href="skillsAll.html#sense-motive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sense Motive</a> +11, <a href="skillsAll.html#spot" style={{
              "color": "rgb(87, 158, 182)"
            }}>Spot</a>
+11, <a href="skillsAll.html#survival" style={{
              "color": "rgb(87, 158, 182)"
            }}>Survival</a> +11 (+13 following
tracks)</td>
          <td></td>
          <td><a href="skillsAll.html#bluff" style={{
              "color": "rgb(87, 158, 182)"
            }}>Bluff</a> +16, <a href="skillsAll.html#climb" style={{
              "color": "rgb(87, 158, 182)"
            }}>Climb</a>
+17, <a href="skillsAll.html#concentration" style={{
              "color": "rgb(87, 158, 182)"
            }}>Concentration</a>
+15, <a href="skillsAll.html#diplomacy" style={{
              "color": "rgb(87, 158, 182)"
            }}>Diplomacy</a> +8, <a href="skillsAll.html#disguise" style={{
              "color": "rgb(87, 158, 182)"
            }}>Disguise</a>
+4 (+6 acting), <a href="skillsAll.html#hide" style={{
              "color": "rgb(87, 158, 182)"
            }}>Hide </a> +10*, <a href="skillsAll.html#intimidate" style={{
              "color": "rgb(87, 158, 182)"
            }}>Intimidate</a>
+18, <a href="skillsAll.html#jump" style={{
              "color": "rgb(87, 158, 182)"
            }}>Jump</a>
+21, <a href="skillsAll.html#listen" style={{
              "color": "rgb(87, 158, 182)"
            }}>Listen</a>
+16, <a href="skillsAll.html#move-silently" style={{
              "color": "rgb(87, 158, 182)"
            }}>Move Silently</a> +14, <a href="skillsAll.html#sense-motive" style={{
              "color": "rgb(87, 158, 182)"
            }}>Sense
Motive</a> +16, <a href="skillsAll.html#spot" style={{
              "color": "rgb(87, 158, 182)"
            }}>Spot</a> +16 <a href="skillsAll.html#survival" style={{
              "color": "rgb(87, 158, 182)"
            }}>Survival</a>
+16 (+18
following tracks), <a href="skillsAll.html#tumble" style={{
              "color": "rgb(87, 158, 182)"
            }}>Tumble</a> +16</td>
        </tr>
        <tr>
          <td><b>Feats:</b></td>
          <td><a href="featsAll.html#combat-reflexes" style={{
              "color": "rgb(87, 158, 182)"
            }}>Combat Reflexes</a>, <a href="featsAll.html#improved-initiative" style={{
              "color": "rgb(87, 158, 182)"
            }}>Improved Initiative</a>, <a href="featsAll.html#track" style={{
              "color": "rgb(87, 158, 182)"
            }}>Track</a></td>
          <td></td>
          <td><a href="featsAll.html#combat-casting" style={{
              "color": "rgb(87, 158, 182)"
            }}>Combat Casting</a>, <a href="featsAll.html#combat-reflexes" style={{
              "color": "rgb(87, 158, 182)"
            }}>Combat
Reflexes</a>, <a href="featsAll.html#improved-initiative" style={{
              "color": "rgb(87, 158, 182)"
            }}>Improved Initiative</a>, <a href="featsAll.html#track" style={{
              "color": "rgb(87, 158, 182)"
            }}>Track</a></td>
        </tr>
        <tr className="odd-row">
          <td><b>Environment</b></td>
          <td>An evil-aligned plane</td>
          <td></td>
          <td>An evil-aligned plane</td>
        </tr>
        <tr>
          <td><b>Organization:</b></td>
          <td>Solitary or pack (3&ndash;6)</td>
          <td></td>
          <td>Solitary or pack (3&ndash;6)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Challenge Rating:</b></td>
          <td>4</td>
          <td></td>
          <td>5</td>
        </tr>
        <tr>
          <td><b>Treasure:</b></td>
          <td>Double standard</td>
          <td></td>
          <td>Double standard</td>
        </tr>
        <tr className="odd-row">
          <td><b>Alignment:</b></td>
          <td>Always lawful evil</td>
          <td></td>
          <td>Always lawful evil</td>
        </tr>
        <tr>
          <td><b>Advancement:</b></td>
          <td>Special (see below)</td>
          <td></td>
          <td>Special (see below)</td>
        </tr>
        <tr className="odd-row">
          <td><b>Level Adjustment:</b></td>
          <td>&mdash;</td>
          <td></td>
          <td>&mdash;</td>
        </tr>
      </tbody>
    </table>
    <p><a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG22b.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a>A barghest
is a lupine fiend that can take the shape of a wolf
or a goblin. In its natural form, it resembles a goblin&ndash;wolf
hybrid
with terrible jaws and sharp claws. As whelps, barghests are nearly
indistinguishable from wolves, except for their size and claws. As they
grow larger and stronger, their skin darkens to bluishred and
eventually becomes blue altogether.</p>
    <p>A full-grown barghest is about 6 feet long and weighs 180
pounds. A barghest&rsquo;s eyes glow orange when the creature becomes
excited. 
    </p>
    <p>Barghests speak Goblin, Worg, and Infernal.&nbsp;</p>
    <h5>COMBAT</h5>
    <p className="initial">Barghests can claw and bite, no matter what
their form, and usually disdain weapons. Though they love killing, they
have little stomach for direct combat and attack from ambush whenever
possible. Barghests start a combat by using <a href="spellsC.html#crushing-despair" style={{
        "color": "rgb(87, 158, 182)"
      }}>crushing
despair</a> and <a href="spellsC.html#charm-monster" style={{
        "color": "rgb(87, 158, 182)"
      }}>charm
monster</a> to keep opponents off balance. They try to stay away from
the
enemy&rsquo;s main strength.</p>
    <p>A barghest&rsquo;s natural weapons, as well as any weapons it
wields, are treated as evil-aligned and lawful-aligned for the purpose
of overcoming <a href="monsterTypes.html#DR" style={{
        "color": "rgb(87, 158, 182)"
      }}>damage reduction</a>. Its natural
weapons are treated as
magic weapons for the purpose of overcoming damage reduction.</p>
    <p><b>Spell-Like Abilities</b>: <span style={{
        "fontStyle": "italic"
      }}>At
will</span>&mdash;<a href="spellsAtoB.html#blink" style={{
        "color": "rgb(87, 158, 182)"
      }}>blink</a>, <a href="spellsHtoL.html#levitate" style={{
        "color": "rgb(87, 158, 182)"
      }}>levitate</a>,
      <a href="spellsMtoO.html#misdirection" style={{
        "color": "rgb(87, 158, 182)"
      }}>misdirection</a> (DC 14), <a href="spellsPtoR.html#rage" style={{
        "color": "rgb(87, 158, 182)"
      }}>rage</a>
(DC 15); <span style={{
        "fontStyle": "italic"
      }}>1/day</span>&mdash;<a href="spellsC.html#charm-monster" style={{
        "color": "rgb(87, 158, 182)"
      }}>charm
monster</a> (DC 16), <a href="spellsC.html#crushing-despair" style={{
        "color": "rgb(87, 158, 182)"
      }}>crushing despair</a> (DC 16), <a href="spellsDtoE.html#dimension-door" style={{
        "color": "rgb(87, 158, 182)"
      }}>dimension
door</a>. Caster level equals the
barghest&rsquo;s HD. The save DCs are Charisma-based.</p>
    <p><b>Feed (Su)</b>: When a barghest slays a humanoid opponent,
it can feed on the corpse, devouring both flesh and life force, as a
full-round action. Feeding destroys the victim&rsquo;s body and
prevents any
form of raising or resurrection that requires part of the corpse. There
is a 50% chance that a <a href="spellsTtoZ.html#wish" style={{
        "color": "rgb(87, 158, 182)"
      }}>wish</a>, <a href="spellsMtoO.html#miracle" style={{
        "color": "rgb(87, 158, 182)"
      }}>miracle</a>,
or <a href="spellsTtoZ.html#true-resurrection" style={{
        "color": "rgb(87, 158, 182)"
      }}>true resurrection</a> spell can
restore a devoured victim to life. Check once for each destroyed
creature. If the check fails, the creature cannot be brought back to
life by mortal magic.</p>
    <p>A barghest advances in Hit Dice by consuming corpses in this
fashion. For every three suitable corpses a barghest devours, it gains
1 Hit Die, and its Strength, Constitution, and natural armor increase
by +1. Its attack bonus and saves improve as normal for an outsider of
its Hit Dice, and it gains skill points, feats, and ability score
improvements normally.</p>
    <p>The barghest only advances by consuming the corpses of
creatures whose Hit Dice or levels are equal to or greater than its own
current total. A barghest that reaches 9 Hit Dice through feeding
immediately becomes a greater barghest upon completion of the act.</p>
    <p><b>Change Shape (Su)</b>: A barghest can assume the shape of a
      <a href="monstersG.html#goblin" style={{
        "color": "rgb(87, 158, 182)"
      }}>goblin</a>
or a <a href="monstersAnimal.html#wolf" style={{
        "color": "rgb(87, 158, 182)"
      }}>wolf</a> as a standard action. In
goblin form, a barghest
cannot use its natural weapons but can wield weapons and wear armor. In
wolf form, a barghest loses its claw attacks but retains its bite
attack.</p>
    <p><b>Pass Without Trace (Ex)</b>: A barghest in wolf form can
use <a href="spellsPtoR.html#pass-without-trace" style={{
        "color": "rgb(87, 158, 182)"
      }}>pass without trace</a> (as the
spell) as a free action.</p>
    <p><b>Skills</b>: *A barghest in wolf form gains a +4
circumstance bonus on <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a> checks.</p>
    <h5>GREATER BARGHEST</h5>
    <p className="initial">A barghest that reaches 9 Hit Dice through
feeding becomes a greater barghest. These creatures can change shape
into a goblinlike creature of Large size (about 8 feet tall and 400
pounds) or a <a href="monstersDitoDo.html#dire-wolf" style={{
        "color": "rgb(87, 158, 182)"
      }}>dire wolf</a>. In goblin form, a
greater barghest cannot use
its natural weapons but can wield weapons and wear armor. In dire wolf
form, a greater barghest loses its claw attacks but retains its bite
attack.</p>
    <p>A greater barghest can reach a maximum of 18 Hit Dice through
feeding.</p>
    <p><b>Spell-Like Abilities</b>: In addition to the spelllike
abilities all barghests possess, a greater barghest gains the following
abilities. <span style={{
        "fontStyle": "italic"
      }}>At will</span>&mdash;<a href="spellsHtoL.html#invisibility-sphere" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisibility sphere</a>; <span style={{
        "fontStyle": "italic"
      }}>1/day</span>&mdash;<a href="spellsAtoB.html#bulls-strength-mass" style={{
        "color": "rgb(87, 158, 182)"
      }}>mass bull&rsquo;s strength</a>, <a href="spellsDtoE.html#enlarge-mass" style={{
        "color": "rgb(87, 158, 182)"
      }}>mass
enlarge</a>. Caster level
equals the greater barghest&rsquo;s HD.</p>
    <h6>Combat</h6>
    <p className="initial">Occasionally, a greater barghest uses a magic
two-handed weapon in combat instead of its claws, giving it multiple
attacks (attack bonus +13/+8). It can also make one bite attack (attack
bonus +8) each round. The save DC against a greater barghest&rsquo;s
spell-like abilities is 14 + spell level.</p>
    <h3><a id="basilisk"></a>BASILISK</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG23.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Basilisk
Medium <a href="monsterTypes.html#magical-beast" style={{
        "color": "black"
      }}>Magical
Beast</a></span> <span className="stat-block"><b>Hit Dice</b>:
6d10+12 (45 hp)</span> <span className="stat-block"><b>Initiative</b>:
&ndash;1</span> <span className="stat-block"><b>Speed</b>: 20 ft. (4
squares)</span> <span className="stat-block"><b>Armor Class</b>: 16
(&ndash;1 Dex, +7
natural), touch 9, flat-footed 16</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +6/+8</span> <span className="stat-block"><b>Attack</b>:
Bite +8 melee (1d8+3)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +8 melee (1d8+3)</span> <span className="stat-block"><b>Space/Reach</b>:
5 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
Petrifying <a href="monsterTypes.html#gaze" style={{
        "color": "rgb(87, 158, 182)"
      }}>gaze</a></span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., low-light vision</span> <span className="stat-block"><b>Saves</b>:
Fort +9, Ref +4, Will +3</span> <span className="stat-block"><b>Abilities</b>:
Str 15, Dex 8, Con 15,
Int 2, Wis 12, Cha 11</span> <span className="stat-block"><b>Skills</b>: <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+0*, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+7, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+7</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#blind-fight" style={{
        "color": "rgb(87, 158, 182)"
      }}>Blind-Fight</a>, <a href="featsAll.html#great-fortitude" style={{
        "color": "rgb(87, 158, 182)"
      }}>Great
Fortitude</a></span> <span className="stat-block"><b>Environment</b>:
Warm deserts</span> <span className="stat-block"><b>Organization</b>:
Solitary or colony
(3&ndash;6)</span> <span className="stat-block"><b>Challenge Rating</b>: 5</span>
    <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 7&ndash;10 HD (Medium);
11&ndash;18 HD (Large)</span> <span className="stat-block"><b>Level
Adjustment</b>:
&mdash;</span>
    <p>A basilisk is a reptilian monster that petrifies living
creatures with a mere gaze. A basilisk usually has a dull brown body
with a yellowish underbelly. Some specimens sport a short, curved horn
atop the nose. An adult basilisk&rsquo;s body grows to about 6 feet
long, not
including its tail, which can reach an additional length of 5 to 7
feet. The creature weighs about 300 pounds.</p>
    <h5>COMBAT</h5>
    <p className="initial">A basilisk relies on its <a href="monsterTypes.html#gaze" style={{
        "color": "rgb(87, 158, 182)"
      }}>gaze
attack</a>, biting
only when opponents come within reach. Though it has eight legs, its
slow metabolism renders it relatively sluggish, so it does not expend
energy unnecessarily. Intruders who flee a basilisk rather than fight
can expect, at best, a halfhearted pursuit. These creatures tend to
spend most of their time lying in wait for prey, which includes small
mammals, birds, reptiles, and similar creatures.</p>
    <p><b>Petrifying Gaze (Su)</b>: Turn to stone permanently, range
30 feet; Fortitude DC 13 negates. The save DC is Charisma-based.</p>
    <p><b>Skills</b>: *The basilisk&rsquo;s dull coloration and its
ability
to remain motionless for long periods of time grant it a +4 racial
bonus on <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a> checks in natural settings.</p>
    <h3><a id="behir"></a>BEHIR</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG25.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Huge <a href="monsterTypes.html#magical-beast" style={{
        "color": "black"
      }}>Magical Beast</a></span> <span className="stat-block"><b>Hit
Dice</b>: 9d10+45 (94 hp)</span> <span className="stat-block"><b>Initiative</b>:
+1</span> <span className="stat-block"><b>Speed</b>: 40 ft. (8 squares),
climb
15 ft.</span> <span className="stat-block"><b>Armor Class</b>: 20
(&ndash;2
size, +1 Dex,
+11 natural), touch 9, flat-footed 19</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +9/+25</span> <span className="stat-block"><b>Attack</b>:
Bite +15 melee (2d4+12)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +15 melee
(2d4+12)</span> <span className="stat-block"><b>Space/Reach</b>: 15 ft./10
ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#breath-weapon" style={{
        "color": "rgb(87, 158, 182)"
      }}>Breath
weapon</a>, <a href="monsterTypes.html#constrict" style={{
        "color": "rgb(87, 158, 182)"
      }}>constrict</a> 2d8+8, <a href="monsterTypes.html#improved-grab" style={{
        "color": "rgb(87, 158, 182)"
      }}>improved grab</a>, <a href="monsterTypes.html#rake" style={{
        "color": "rgb(87, 158, 182)"
      }}>rake</a>
1d4+4, <a href="monsterTypes.html#swallow-whole" style={{
        "color": "rgb(87, 158, 182)"
      }}>swallow whole</a></span> <span className="stat-block"><b>Special Qualities</b>: Can&rsquo;t be
tripped, darkvision 60 ft., immunity to electricity, low-light vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span>
    <span className="stat-block"><b>Saves</b>: Fort +11, Ref +7,
Will +5</span> <span className="stat-block"><b>Abilities</b>: Str 26, Dex
13, Con
21, Int 7, Wis 14, Cha 12</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
+16, <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+5, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+4, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+4, <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a>
+2</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#cleave" style={{
        "color": "rgb(87, 158, 182)"
      }}>Cleave</a>,
      <a href="featsAll.html#power-attack" style={{
        "color": "rgb(87, 158, 182)"
      }}>Power
Attack</a>, <a href="featsAll.html#track" style={{
        "color": "rgb(87, 158, 182)"
      }}>Track</a></span> <span className="stat-block"><b>Environment</b>: Warm
hills</span> <span className="stat-block"><b>Organization</b>: Solitary or
pair</span> <span className="stat-block"><b>Challenge Rating</b>: 8</span>
    <span className="stat-block"><b>Treasure</b>: Standard</span> <span className="stat-block"><b>Alignment</b>: Often neutral</span> <span className="stat-block"><b>Advancement</b>: 10&ndash;13 HD (Huge);
14&ndash;27 HD (Gargantuan)</span> <span className="stat-block"><b>Level
Adjustment</b>: &mdash;</span>
    <p>The behir is a serpentine monster that can slither like a
snake or use its dozen legs to move with considerable speed. A behir is
about 40 feet long and weighs about 4,000 pounds. It can fold its limbs
close to its long, narrow body and slither in snake fashion if it
desires. The coloration of behirs ranges from ultramarine to deep blue
with bands of gray-brown. The belly is pale blue. The two large horns
curving back over the head look dangerous but are actually used for
preening the creature&rsquo;s scales, not for fighting.</p>
    <p>Behirs speak Common.</p>
    <h5>COMBAT</h5>
    <p className="initial">A behir usually bites and <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grabs</a>
its prey
first, then either <a href="monsterTypes.html#swallow-whole" style={{
        "color": "rgb(87, 158, 182)"
      }}>swallows</a> or <a href="monsterTypes.html#constrict" style={{
        "color": "rgb(87, 158, 182)"
      }}>constricts</a>
the opponent. It can employ
its claws only against foes caught in its coils. If beset by a large
number of foes, it uses its breath weapon.</p>
    <p><b>Breath Weapon (Su)</b>: 20-foot line, once every 10 rounds,
damage 7d6 electricity, Reflex DC 19 half. The save DC is
Constitution-based.</p>
    <p><b>Constrict (Ex)</b>: A behir deals 2d8+8 points of damage
with a successful <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a> check. It can make six
rake attacks against a
grappled foe as well.</p>
    <p><b>Improved Grab (Ex)</b>: To use this ability, a behir must
hit a creature of any size with its bite attack. It can then attempt to
start a <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a> as a free action without
provoking an attack of
opportunity.</p>
    <p>If it wins the grapple check, it it establishes a hold and can
attempt to constrict the opponent or swallow the opponent in the
following round.</p>
    <p><b>Rake (Ex)</b>: Six claws, attack bonus +15 melee, damage
1d4+4.</p>
    <p><b>Swallow Whole (Ex)</b>: A behir can try to swallow a
grabbed Medium or smaller opponent by making a successful <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a>
check. A behir that swallows an opponent can use its Cleave feat to
bite and grab another opponent.</p>
    <p>A swallowed creature takes 2d8+8 points of bludgeoning damage
and 8 points of acid damage per round from the behir&rsquo;s gizzard. A
swallowed creature can cut its way out by using a light slashing or
piercing weapon to deal 25 points of damage to the gizzard (AC 15).
Once the creature exits, muscular action closes the hole; another
swallowed opponent must cut its own way out.</p>
    <p>A behir&rsquo;s gizzard can hold 2 Medium, 8 Small, 32 Tiny,
or 128
Diminutive or smaller opponents.</p>
    <p><b>Skills</b>: Behirs have a +8 racial bonus on <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
checks
and can always choose to take 10 on Climb checks, even if rushed or
threatened.</p>
    <h3><a id="belker"></a>BELKER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG27.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Large <a href="monsterTypes.html#elemental" style={{
        "color": "black"
      }}>Elemental</a> (<a href="monsterTypes.html#air" style={{
        "color": "black"
      }}>Air</a>, <a href="monsterTypes.html#extraplanar" style={{
        "color": "black"
      }}>Extraplanar</a>)</span> <span className="stat-block"><b>Hit
Dice</b>: 7d8+7 (38 hp)</span> <span className="stat-block"><b>Initiative</b>:
+5</span> <span className="stat-block"><b>Speed</b>: 30 ft. (6 squares),
fly 50
ft. (perfect)</span> <span className="stat-block"><b>Armor Class</b>: 22
(&ndash;1 size, +5 Dex,
+8 natural), touch 14, flat-footed 17</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +5/+11</span> <span className="stat-block"><b>Attack</b>:
Wing +9 melee (1d6+2)</span> <span className="stat-block"><b>Full Attack</b>:
2 wings +9 melee
(1d6+2) and bite +4 melee (1d4+1) and 2 claws +4 melee (1d3+1)</span> <span className="stat-block"><b>Space/Reach</b>: 10 ft./10 ft.</span> <span className="stat-block"><b>Special Attacks</b>: Smoke claws</span> <span className="stat-block"><b>Special Qualities</b>: Darkvision 60
ft., <a href="monsterTypes.html#elemental" style={{
        "color": "rgb(87, 158, 182)"
      }}>elemental traits</a>, smoke form</span>
    <span className="stat-block"><b>Saves</b>:
Fort +3, Ref +10, Will +2</span> <span className="stat-block"><b>Abilities</b>:
Str 14, Dex 21, Con
13, Int 6, Wis 11, Cha 11</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+7, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a>
+9, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+7</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="monsterFeats.html#multiattack" style={{
        "color": "rgb(87, 158, 182)"
      }}>Multiattack</a>, <a href="featsAll.html#weapon-finesse" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon
Finesse</a></span> <span className="stat-block"><b>Environment</b>:
Elemental Plane of
Air</span> <span className="stat-block"><b>Organization</b>: Solitary,
pair, or
clutch (3&ndash;4)</span> <span className="stat-block"><b>Challenge Rating</b>:
6</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Usually neutral evil</span> <span className="stat-block"><b>Advancement</b>: 8&ndash;10 HD (Large);
11&ndash;21 HD (Huge)</span> <span className="stat-block"><b>Level
Adjustment</b>:
&mdash;</span>
    <p>Belkers are creatures from the Plane of Air. They are composed
primarily of smoke. Although undeniably evil, they are very reclusive
and usually have no interest in the affairs of others. A belker&rsquo;s
winged shape makes it look distinctly demonic. It is about 7 feet long
and weighs about 8 pounds.</p>
    <p>Belkers speak Auran.</p>
    <h5>COMBAT</h5>
    <p className="initial">In most cases, a belker fights with its nasty
claws and painful bite.</p>
    <p><b>Smoke Claws (Ex)</b>: A belker in smoke form (see below)
can engulf opponents by moving on top of them. It fills the air around
one Medium or smaller opponent without provoking an attack of
opportunity. The target must succeed on a DC 14 Fortitude save or
inhale part of the creature. The save DC is Constitution-based. Smoke
inside the victim solidifies into a claw and begins to rip at the
surrounding organs, dealing 3d4 points of damage per round. An affected
creature can attempt another Fortitude save each subsequent round to
cough out the semivaporous menace.</p>
    <p><b>Smoke Form (Su)</b>: Most of the time a belker is more or
less solid, but at will it can assume smoke form. It can switch forms
once per round as a free action and can spend up to 20 rounds per day
in smoke form. A belker in smoke form can fly at a speed of 50 feet
(perfect). The ability is otherwise similar to a <a href="spellsFtoG.html#gaseous-form" style={{
        "color": "rgb(87, 158, 182)"
      }}>gaseous
form</a> spell
(caster level 7th).</p>
    <p><b>Skills</b>: Belkers have a +4 racial bonus on <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move
Silently</a>
checks.</p>
    <h3><a id="blink-dog"></a>BLINK DOG</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG28a.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Medium <a href="monsterTypes.html#magical-beast" style={{
        "color": "black"
      }}>Magical Beast</a></span> <span className="stat-block"><b>Hit Dice</b>: 4d10 (22 hp)</span> <span className="stat-block"><b>Initiative</b>: +3</span> <span className="stat-block"><b>Speed</b>: 40 ft. (8 squares)</span> <span className="stat-block"><b>Armor Class</b>: 16 (+3 Dex, +3
natural), touch 13, flat-footed 13</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +4/+4</span> <span className="stat-block"><b>Attack</b>:
Bite +4 melee (1d6)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +4 melee (1d6)</span> <span className="stat-block"><b>Space/Reach</b>:
5 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
&mdash;</span> <span className="stat-block"><b>Special Qualities</b>: <a href="spellsAtoB.html#blink" style={{
        "color": "rgb(87, 158, 182)"
      }}>Blink</a>,
darkvision 60 ft., <a href="spellsDtoE.html#dimension-door" style={{
        "color": "rgb(87, 158, 182)"
      }}>dimension door</a>, low-light
vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +4, Ref +7, Will +4</span> <span className="stat-block"><b>Abilities</b>: Str 10, Dex 17, Con
10, Int 10, Wis 13, Cha 11</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+3, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+5, <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense
Motive</a> +3, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> +5, <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a>
+4</span><span className="stat-block"><b>Feats</b>: <a href="featsAll.html#iron-will" style={{
        "color": "rgb(87, 158, 182)"
      }}>Iron
Will</a>, <a href="featsAll.html#run" style={{
        "color": "rgb(87, 158, 182)"
      }}>Run</a>,
      <a href="featsAll.html#track" style={{
        "color": "rgb(87, 158, 182)"
      }}>Track<sup>B</sup></a></span>
    <span className="stat-block"><b>Environment</b>:
Temperate plains</span> <span className="stat-block"><b>Organization</b>:
Solitary, pair, or
pack (7&ndash;16)</span> <span className="stat-block"><b>Challenge Rating</b>:
2</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Usually lawful good</span> <span className="stat-block"><b>Advancement</b>: 5&ndash;7 HD (Medium);
8&ndash;12 HD (Large)</span> <span className="stat-block"><b>Level
Adjustment</b>:
+2 (cohort)</span>
    <p>The blink dog is an intelligent canine that has a limited
teleportation ability.</p>
    <p>Blink dogs have their own language, a mixture of barks, yaps,
whines, and growls that can transmit complex information.</p>
    <h5>COMBAT</h5>
    <p className="initial">Blink dogs hunt in packs, teleporting in a
seemingly random fashion until they surround their prey, allowing some
of them to take advantage of flanking.</p>
    <p><b>Blink (Su)</b>: A blink dog can use <a href="spellsAtoB.html#blink" style={{
        "color": "rgb(87, 158, 182)"
      }}>blink</a>
as the spell
(caster level 8th), and can evoke or end the effect as a free action.</p>
    <p><b>Dimension Door (Su)</b>: A blink dog can teleport, as <a href="spellsDtoE.html#dimension-door" style={{
        "color": "rgb(87, 158, 182)"
      }}>dimension
door</a> (caster level
8th), once per round as a free action. The
ability affects only the blink dog, which never appears within a solid
object and can act immediately after teleporting.</p>
    <h3><a id="bodak"></a>BODAK</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG28b.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Medium <a href="monsterTypes.html#undead" style={{
        "color": "black"
      }}>Undead</a> (<a href="monsterTypes.html#extraplanar" style={{
        "color": "black"
      }}>Extraplanar</a>)</span>
    <span className="stat-block"><b>Hit Dice</b>: 9d12 (58 hp)</span> <span className="stat-block"><b>Initiative</b>: +6</span> <span className="stat-block"><b>Speed</b>: 20 ft. (4 squares)</span> <span className="stat-block"><b>Armor Class</b>: 20 (+2 Dex, +8
natural), touch 12, flat-footed 18</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +4/+5</span> <span className="stat-block"><b>Attack</b>:
Slam +6 melee (1d8+1)</span> <span className="stat-block"><b>Full Attack</b>:
Slam +6 melee (1d8+1)</span> <span className="stat-block"><b>Space/Reach</b>:
5 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
Death <a href="monsterTypes.html#gaze" style={{
        "color": "rgb(87, 158, 182)"
      }}>gaze</a></span> <span className="stat-block"><b>Special Qualities</b>: <a href="monsterTypes.html#DR" style={{
        "color": "rgb(87, 158, 182)"
      }}>Damage
reduction</a> 10/cold iron, darkvision 60 ft., immunity to electricity,
      <a href="monsterTypes.html#resistance-to-energy" style={{
        "color": "rgb(87, 158, 182)"
      }}>resistance to acid</a> 10 and fire
10, <a href="monsterTypes.html#undead" style={{
        "color": "rgb(87, 158, 182)"
      }}>undead traits</a>, vulnerability to
sunlight</span> <span className="stat-block"><b>Saves</b>: Fort +3, Ref
+5, Will +7</span> <span className="stat-block"><b>Abilities</b>: Str 13,
Dex 15, Con &mdash;,
Int 6, Wis 12, Cha 12</span> <span className="stat-block"><b>Skills</b>: <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+11, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a>
+10, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+11</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#dodge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Dodge</a>,
      <a href="featsAll.html#improved-initiative" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved
Initiative</a>, <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon Focus</a> (slam)</span> <span className="stat-block"><b>Environment</b>:
A chaotic
evil-aligned plane</span> <span className="stat-block"><b>Organization</b>:
Solitary or gang
(2&ndash;4)</span> <span className="stat-block"><b>Challenge Rating</b>: 8</span>
    <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always chaotic evil</span> <span className="stat-block"><b>Advancement</b>: 10&ndash;13 HD (Medium);
14&ndash;27 HD (Large)</span> <span className="stat-block"><b>Level
Adjustment</b>:
&mdash;</span>
    <p>Bodaks are the undead remnants of humanoids who have been
destroyed by the touch of absolute evil.</p>
    <p>A bodak retains fleeting memories of its past life and can
speak Common (or some other humanoid language).</p>
    <h5>COMBAT</h5>
    <p className="initial"><b>Death Gaze (Su)</b>: Death, range 30 feet,
Fortitude DC 15 negates. Humanoids who die from this attack are
transformed into bodaks 24 hours later. The save DC is Charisma-based.</p>
    <p><b>Vulnerability to Sunlight (Ex)</b>: Bodaks loathe sunlight,
for its merest touch burns their impure flesh. Each round of exposure
to the direct rays of the sun deals 1 point of damage to the creature.</p>
    <h3><a id="bralani"></a>BRALANI</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG94.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Medium <a href="monsterTypes.html#outsider" style={{
        "color": "black"
      }}>Outsider</a> (<a href="monsterTypes.html#chaotic" style={{
        "color": "black"
      }}>Chaotic</a>, <a href="monsterTypes.html#extraplanar" style={{
        "color": "black"
      }}>Extraplanar</a>,
      <a href="monsterTypes.html#good" style={{
        "color": "black"
      }}>Good</a>)</span>
    <span className="stat-block"><b>Hit Dice</b>: 6d8+18 (45 hp)</span> <span className="stat-block"><b>Initiative</b>: +8</span> <span className="stat-block"><b>Speed</b>: 40 ft. (8 squares), fly
100 ft. (perfect)</span> <span className="stat-block"><b>Armor Class</b>:
20 (+4 Dex, +6
natural), touch 14, flat-footed 16</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +6/+10</span> <span className="stat-block"><b>Attack</b>:
+1 holy scimitar +11
melee (1d6+4/18&ndash;20) or +1 holy composite longbow (+4 Str bonus)
+11
ranged (1d8+5/x3) or slam +10 melee (1d6+4)</span> <span className="stat-block"><b>Full Attack</b>: +1 holy scimitar
+11/+6 melee (1d6+4/18&ndash;20) or +1 holy composite longbow (+4 Str
bonus)
+11/+6 ranged (1d8+5/x3) or slam +10 melee (1d6+4)</span> <span className="stat-block"><b>Space/Reach</b>: 5 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>: Spell-like
abilities, whirlwind blast</span> <span className="stat-block"><b>Special
Qualities</b>: <a href="monsterTypes.html#alternate-form" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alternate
form</a>, <a href="monsterTypes.html#DR" style={{
        "color": "rgb(87, 158, 182)"
      }}>damage reduction</a> 10/cold iron or
evil, darkvision 60 ft.,
immunity to electricity and <a href="abilitiesAndConditions.html#petrified" style={{
        "color": "rgb(87, 158, 182)"
      }}>petrification</a>, low-light vision,
      <a href="monsterTypes.html#resistance-to-energy" style={{
        "color": "rgb(87, 158, 182)"
      }}>resistance
to cold</a> 10 and fire 10, <a href="monsterTypes.html#SR" style={{
        "color": "rgb(87, 158, 182)"
      }}>spell resistance</a> 17, <a href="spellsTtoZ.html#tongues" style={{
        "color": "rgb(87, 158, 182)"
      }}>tongues</a></span>
    <span className="stat-block"><b>Saves</b>: Fort +8, Ref +9, Will +7</span>
    <span className="stat-block"><b>Abilities</b>: Str 18, Dex 18, Con
17, Int 13, Wis 14, Cha 14</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> +12, <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a>
+4, <a href="skillsAll.html#escape-artist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Escape Artist</a> +13, <a href="skillsAll.html#handle-animal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Handle
Animal</a> +11, <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+13, <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
+10, <a href="skillsAll.html#listen" style={{
        "color": "rgb(103, 158, 182)"
      }}>Listen</a>
+13, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a> +13, <a href="skillsAll.html#ride" style={{
        "color": "rgb(87, 158, 182)"
      }}>Ride</a>
+6, <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a> +11, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+13, <a href="skillsAll.html#tumble" style={{
        "color": "rgb(87, 158, 182)"
      }}>Tumble</a>
+13, <a href="skillsAll.html#use-rope" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use Rope</a> +4 (+6 with bindings)</span>
    <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#blind-fight" style={{
        "color": "rgb(87, 158, 182)"
      }}>Blind-Fight</a>, <a href="featsAll.html#improved-initiative" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved Initiative</a></span> <span className="stat-block"><b>Environment</b>:
A chaotic
good-aligned plane</span> <span className="stat-block"><b>Organization</b>:
Solitary, pair, or
squad (3&ndash;5)</span> <span className="stat-block"><b>Challenge Rating</b>:
6</span> <span className="stat-block"><b>Treasure</b>: No coins; double
goods;
standard items</span> <span className="stat-block"><b>Alignment</b>:
Always chaotic good</span> <span className="stat-block"><b>Advancement</b>:
7&ndash;12 HD (Medium);
13&ndash;18 HD (Large)</span> <span className="stat-block"><b>Level
Adjustment</b>:
+5</span>
    <p>In addition to their natural form, bralanis can assume the
shape of a whirlwind or zephyr of dust, snow, or sand.</p>
    <p>Bralanis speak Celestial, Infernal, and Draconic, but can
communicate with almost any creature, thanks to their <a href="spellsTtoZ.html#tongues" style={{
        "color": "rgb(87, 158, 182)"
      }}>tongues</a>
ability.</p>
    <h6>Combat</h6>
    <p className="initial">Bralanis prefer the scimitar and bow, the
weapons of the desert nomads they most closely resemble.</p>
    <p>A bralani&rsquo;s natural weapons, as well as any weapons it
wields,
are treated as chaotic-aligned and good-aligned for the purpose of
overcoming <a href="monsterTypes.html#DR" style={{
        "color": "rgb(87, 158, 182)"
      }}>damage reduction</a>.</p>
    <p><b>Spell-Like Abilities</b>: Caster level 6th. The save
DCs are Charisma-based.
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>At
will</span>&mdash;<a href="spellsAtoB.html#blur" style={{
        "color": "rgb(87, 158, 182)"
      }}>blur</a>, <a href="spellsC.html#charm-person" style={{
        "color": "rgb(87, 158, 182)"
      }}>charm
person</a> (DC
13), <a href="spellsFtoG.html#gust-of-wind" style={{
        "color": "rgb(87, 158, 182)"
      }}>gust of wind</a> (DC 14), <a href="spellsMtoO.html#mirror-image" style={{
        "color": "rgb(87, 158, 182)"
      }}>mirror
image</a>, <a href="spellsTtoZ.html#wind-wall" style={{
        "color": "rgb(87, 158, 182)"
      }}>wind wall</a>; </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>2/day</span>&mdash;<a href="spellsHtoL.html#lightning-bolt" style={{
        "color": "rgb(87, 158, 182)"
      }}>lightning
bolt</a> (DC 15), <a href="spellsC.html#cure-serious-wounds" style={{
        "color": "rgb(87, 158, 182)"
      }}>cure serious wounds</a> (DC 15). 
    </p>
    <p><b>Whirlwind Blast (Su)</b>: When in whirlwind form, a bralani
can attack with a scouring blast of wind, dealing 3d6 points of damage
in a 20-foot line (Reflex DC 16 half ). The save DC is
Constitution-based.</p>
    <p><b>Alternate Form (Su)</b>: A bralani can shift between its
humanoid and whirlwind forms as a standard action. In humanoid form, it
cannot fly or use its whirlwind blast, but it can use its spell-like
abilities and its weapons. In whirlwind form, it can fly, make slam
attacks and whirlwind blast attacks, and use spell-like abilities. </p>
    <p>A bralani remains in one form until it chooses to assume a new
one. A change in form cannot be <a href="spellsDtoE.html#dispel-magic" style={{
        "color": "rgb(87, 158, 182)"
      }}>dispelled</a>, nor does the bralani
revert
to any particular form when killed. A <a href="spellsTtoZ.html#true-seeing" style={{
        "color": "rgb(87, 158, 182)"
      }}>true
seeing</a> spell, however,
reveals both forms simultaneously.</p>
    <p><b>Tongues (Su)</b>: Bralanis can speak with any creature that
has a language, as though using a <a href="spellsTtoZ.html#tongues" style={{
        "color": "rgb(87, 158, 182)"
      }}>tongues</a> spell (caster level
14th).
This ability is always active.</p>
    <h3><a id="bugbear"></a>BUGBEAR</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG29.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Medium <a href="monsterTypes.html#humanoid" style={{
        "color": "black"
      }}>Humanoid</a> (<a href="monsterTypes.html#goblinoid" style={{
        "color": "black"
      }}>Goblinoid</a>)</span>
    <span className="stat-block"><b>Hit Dice</b>: 3d8+3 (16 hp)</span> <span className="stat-block"><b>Initiative</b>: +1</span> <span className="stat-block"><b>Speed</b>: 30 ft. (6 squares)</span> <span className="stat-block"><b>Armor Class</b>: 17 (+1 Dex, +3
natural, +2 leather armor, +1 light wooden shield), touch 11,
flat-footed 16</span> <span className="stat-block"><b>Base Attack/Grapple</b>:
+2/+4</span> <span className="stat-block"><b>Attack</b>: Morningstar +5
melee
(1d8+2) or javelin +3 ranged (1d6+2)</span> <span className="stat-block"><b>Full
Attack</b>: Morningstar +5 melee
(1d8+2) or javelin +3 ranged (1d6+2)</span> <span className="stat-block"><b>Space/Reach</b>:
5 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
&mdash;</span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., <a href="monsterTypes.html#scnet" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>: Fort +2, Ref
+4, Will +1</span> <span className="stat-block"><b>Abilities</b>: Str 15,
Dex 12, Con
13, Int 10, Wis 10, Cha 9</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
+3, <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+4, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+4, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a> +6, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+4</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon
Focus</a>
(morningstar)</span> <span className="stat-block"><b>Environment</b>:
Temperate mountains</span> <span className="stat-block"><b>Organization</b>:
Solitary, gang
(2&ndash;4), or band (11&ndash;20 plus 150% noncombatants plus 2
2nd-level
sergeants and 1 leader of 2nd&ndash;5th level)</span> <span className="stat-block"><b>Challenge Rating</b>: 2</span> <span className="stat-block"><b>Treasure</b>: Standard</span> <span className="stat-block"><b>Alignment</b>: Usually chaotic evil</span> <span className="stat-block"><b>Advancement</b>: By character class</span> <span className="stat-block"><b>Level Adjustment</b>: +1</span>
    <p>Bugbears speak Goblin and Common.</p>
    <h5>COMBAT</h5>
    <p className="initial">Bugbears prefer to ambush opponents whenever
possible. When hunting, they normally send scouts ahead of the main
group that, if they spy prey, return to report and bring up
reinforcements. Bugbear attacks are coordinated, and their tactics are
sound if not brilliant.</p>
    <p><b>Skills</b>: Bugbears have a +4 racial bonus on <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move
Silently</a> checks.</p>
    <h5>BUGBEARS AS CHARACTERS</h5>
    <p className="initial">Bugbear characters possess the following
racial traits. </p>
    <ul>
      <li>
        <p>+4 Strength, +2 Dexterity, +2 Constitution, &ndash;2
Charisma.</p>
      </li>
      <li>
        <p>Medium size.</p>
      </li>
      <li>
        <p>A bugbear&rsquo;s base land speed is 30 feet.</p>
      </li>
      <li>
        <p>Darkvision out to 60 feet.</p>
      </li>
      <li>
        <p>Racial Hit Dice: A bugbear begins with three levels of <a href="monsterTypes.html#humanoid" style={{
            "color": "rgb(87, 158, 182)"
          }}>humanoid</a>,
which provide 3d8 Hit
Dice, a base attack bonus of +2, and
base saving throw bonuses of Fort +1, Ref +3, and Will +1.</p>
      </li>
      <li>
        <p>Racial Skills: A bugbear&rsquo;s humanoid levels give it
skill
points equal to 6 x (2 + Int modifier). Its class skills are <a href="skillsAll.html#climb" style={{
            "color": "rgb(87, 158, 182)"
          }}>Climb</a>,
          <a href="skillsAll.html#hide" style={{
            "color": "rgb(87, 158, 182)"
          }}>Hide</a>, <a href="skillsAll.html#listen" style={{
            "color": "rgb(87, 158, 182)"
          }}>Listen</a>,
          <a href="skillsAll.html#move-silently" style={{
            "color": "rgb(87, 158, 182)"
          }}>Move Silently</a>, <a href="skillsAll.html#search" style={{
            "color": "rgb(87, 158, 182)"
          }}>Search</a>,
and <a href="skillsAll.html#spot" style={{
            "color": "rgb(87, 158, 182)"
          }}>Spot</a>.</p>
      </li>
      <li>
        <p>Racial Feats: A bugbear&rsquo;s humanoid levels give it
two
feats.</p>
      </li>
      <li>
        <p>+3 natural armor bonus.</p>
      </li>
      <li>
        <p>+4 racial bonus on <a href="skillsAll.html#move-silently" style={{
            "color": "rgb(87, 158, 182)"
          }}>Move Silently</a> checks.</p>
      </li>
      <li>
        <p>Automatic Languages: Common, Goblin. Bonus Languages:
Draconic, Elven, Giant, Gnoll, Orc.</p>
      </li>
      <li>
        <p>Favored Class: <a href="rogue.html" style={{
            "color": "rgb(87, 158, 182)"
          }}>Rogue</a>.</p>
      </li>
      <li>
        <p>Level adjustment +1.</p>
      </li>
    </ul>
    <h3><a id="bulette"></a>BULETTE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG30.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Huge <a href="monsterTypes.html#magical-beast" style={{
        "color": "black"
      }}>Magical Beast</a></span> <span className="stat-block"><b>Hit
Dice</b>: 9d10+45 (94 hp)</span> <span className="stat-block"><b>Initiative</b>:
+2</span> <span className="stat-block"><b>Speed</b>: 40 ft. (8 squares),
burrow
10 ft.</span> <span className="stat-block"><b>Armor Class</b>: 22
(&ndash;2
size, +2 Dex,
+12 natural), touch 10, flat-footed 20</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +9/+25</span> <span className="stat-block"><b>Attack</b>:
Bite +16 melee (2d8+8)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +16 melee
(2d8+8) and 2 claws +10 melee (2d6+4)</span> <span className="stat-block"><b>Space/Reach</b>:
15 ft./10 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
Leap</span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., low-light vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a>, <a href="monsterTypes.html#tremorsense" style={{
        "color": "rgb(87, 158, 182)"
      }}>tremorsense</a>
60 ft.</span> <span className="stat-block"><b>Saves</b>: Fort +11, Ref +8,
Will +6</span> <span className="stat-block"><b>Abilities</b>: Str 27, Dex
15, Con
20, Int 2, Wis 13, Cha 6</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
+18, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+9, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+3</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#iron-will" style={{
        "color": "rgb(87, 158, 182)"
      }}>Iron Will</a>, <a href="featsAll.html#track" style={{
        "color": "rgb(87, 158, 182)"
      }}>Track</a>,
      <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon Focus</a> (bite)</span> <span className="stat-block"><b>Environment</b>:
Temperate hills</span> <span className="stat-block"><b>Organization</b>:
Solitary or pair</span> <span className="stat-block"><b>Challenge Rating</b>:
7</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 10&ndash;16 HD (Huge);
17&ndash;27 HD (Gargantuan)</span> <span className="stat-block"><b>Level
Adjustment</b>: &mdash;</span>
    <p>Also known as the landshark, the bulette is a terrifying
predator that lives only to eat. </p>
    <h5>COMBAT</h5>
    <p className="initial">A bulette attacks anything it regards as
edible, choosing the easiest or closest prey first. The only creatures
it refuses to eat are elves (and it dislikes the taste of dwarves).
When burrowing underground, a landshark relies on its <a href="monsterTypes.html#tremorsense" style={{
        "color": "rgb(87, 158, 182)"
      }}>tremorsense</a>
ability to detect prey. When it senses something edible (that is,
senses movement), it breaks to the surface, crest first, and begins its
attack.</p>
    <p><b>Leap (Ex)</b>: A bulette can jump into the air during
combat. This allows it to make four claw attacks instead of two, each
with a +15 attack bonus, but it cannot bite.</p>
    <h3><a id="celestial-creature"></a>CELESTIAL CREATURE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p className="initial">Celestial creatures dwell on the upper planes,
the realms of good, although they resemble beings found on the Material
Plane. They are more regal and more beautiful than their earthly
counterparts.</p>
    <p>Celestial creatures often come in metallic colors (usually
silver, gold, or platinum). They can be mistaken for half-celestials,
more powerful creatures that are created when a celestial mates with a
non-celestial creature.</p>
    <h5>CREATING A CELESTIAL CREATURE</h5>
    <p className="initial">&ldquo;Celestial&rdquo; is an inherited
template that can
be added to any corporeal animal, aberration, animal, dragon, fey,
giant, humanoid, magical beast, monstrous humanoid, plant, or vermin of
good or neutral alignment (referred to hereafter as the base creature).</p>
    <p>A celestial creature uses all the base creature&rsquo;s
statistics
and abilities except as noted here. Do not recalculate the
creature&rsquo;s
Hit Dice, base attack bonus, saves, or skill points if its type changes.</p>
    <p><b>Size and Type</b>: <a href="monsterTypes.html#animal" style={{
        "color": "rgb(87, 158, 182)"
      }}>Animals</a> or <a href="monsterTypes.html#vermin" style={{
        "color": "rgb(87, 158, 182)"
      }}>vermin</a>
with this template
become <a href="monsterTypes.html#magical-beasts" style={{
        "color": "rgb(87, 158, 182)"
      }}>magical beasts</a>, but otherwise
the creature type is unchanged.
Size is unchanged. Celestial creatures encountered on the Material
Plane have the <a href="monsterTypes.html#extraplanar" style={{
        "color": "rgb(87, 158, 182)"
      }}>extraplanar</a> subtype.</p>
    <p><b>Special Attacks</b>: A celestial creature retains all the
special attacks of the base creature and also gains the following
attack.</p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Smite Evil (Su):</span> Once
per day a celestial creature can
make a normal melee attack to deal extra damage equal to its HD
(maximum of +20) against an evil foe.</p>
    <p><b>Special Qualities</b>: A celestial creature retains all the
special qualities of the base creature and also gains the following
qualities. </p>
    <ul>
      <li>
        <p>Darkvision out to 60 feet.</p>
      </li>
      <li>
        <p><a href="monsterTypes.html#DR" style={{
            "color": "rgb(87, 158, 182)"
          }}>Damage reduction</a> (see the table
below).</p>
      </li>
      <li>
        <p><a href="monsterTypes.html#resistance-to-energy" style={{
            "color": "rgb(87, 158, 182)"
          }}>Resistance to acid</a>, cold, and
electricity (see the table
below).</p>
      </li>
      <li>
        <p><a href="monsterTypes.html#SR" style={{
            "color": "rgb(87, 158, 182)"
          }}>Spell resistance</a> equal to HD + 5
(maximum 25).</p>
      </li>
    </ul>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "50%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "20%"
          }}>Hit Dice</th>
          <th style={{
            "width": "40%"
          }}>Resistance to
Acid, Cold, Electricity</th>
          <th style={{
            "width": "40%"
          }}>Damage Reduction</th>
        </tr>
        <tr className="odd-row">
          <td>1&ndash;3</td>
          <td>5</td>
          <td>&mdash;</td>
        </tr>
        <tr>
          <td>4&ndash;7</td>
          <td>5</td>
          <td>5/magic</td>
        </tr>
        <tr className="odd-row">
          <td>8&ndash;11</td>
          <td>10</td>
          <td>5/magic</td>
        </tr>
        <tr>
          <td className="last-row">12 or more</td>
          <td className="last-row">10</td>
          <td className="last-row">10/magic</td>
        </tr>
      </tbody>
    </table>
    <p>If the base creature already has one or more of these special
qualities, use the better value.</p>
    <p>If a celestial creature gains damage reduction, its natural
weapons are treated as magic weapons for the purpose of overcoming
damage reduction.</p>
    <p><b>Abilities</b>: Same as the base creature, but Intelligence
is at least 3.</p>
    <p><b>Environment</b>: Any good-aligned plane.</p>
    <p><b>Challenge Rating</b>: HD 3 or less, as base creature; HD 4
to 7, as base creature +1; HD 8 or more, as base creature +2.</p>
    <p><b>Alignment</b>: Always good (any).</p>
    <p><b>Level Adjustment</b>: Same as the base creature +2.</p>
    <h3><a id="centaur"></a>CENTAUR</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG32.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Large <a href="monsterTypes.html#monstrous-humanoid" style={{
        "color": "rgb(87, 158, 182)"
      }}>Monstrous Humanoid</a></span> <span className="stat-block"><b>Hit Dice</b>: 4d8+8 (26 hp)</span> <span className="stat-block"><b>Initiative</b>: +2</span> <span className="stat-block"><b>Speed</b>: 50 ft. (10 squares)</span> <span className="stat-block"><b>Armor Class</b>: 14 (&ndash;1 size, +2 Dex,
+3 natural), touch 11, flat-footed 12</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +4/+12</span> <span className="stat-block"><b>Attack</b>:
Longsword +7 melee
(2d6+6/19&ndash;20) or composite longbow (+4 Str bonus) +5 ranged
(2d6+4/x3)</span> <span className="stat-block"><b>Full Attack</b>:
Longsword +7 melee
(2d6+6/19&ndash;20) and 2 hooves +3 melee (1d6+2); or composite longbow
(+4
Str bonus) +5 ranged (2d6+4/x3)</span> <span className="stat-block"><b>Space/Reach</b>:
10 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
&mdash;</span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft.</span> <span className="stat-block"><b>Saves</b>: Fort +3, Ref +6,
Will +5</span> <span className="stat-block"><b>Abilities</b>: Str 18, Dex
14, Con
15, Int 8, Wis 13, Cha 11</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+3, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move Silently</a>
+4, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+3, <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a>
+2 </span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#dodge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Dodge</a>,
      <a href="featsAll.html#weapon-focus" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon Focus</a> (hoof )</span> <span className="stat-block"><b>Environment</b>:
Temperate forests</span> <span className="stat-block"><b>Organization</b>:
Solitary, company
(5&ndash;8), troop (8&ndash;18 plus 1 leader of 2nd&ndash;5th level),
or tribe (20&ndash;150
plus 30% noncombatants plus 10 3rd-level sergeants, 5 5th-level
lieutenants, and 1 leader of 5th&ndash;9th level)</span> <span className="stat-block"><b>Challenge Rating</b>: 3</span> <span className="stat-block"><b>Treasure</b>: Standard</span> <span className="stat-block"><b>Alignment</b>: Usually neutral good</span> <span className="stat-block"><b>Advancement</b>: By character class</span> <span className="stat-block"><b>Level Adjustment</b>: +2</span>
    <p>A centaur is as big as a heavy horse, but much taller and
slightly heavier. A centaur is about 7 feet tall and weighs about 2,100
pounds.</p>
    <p>Centaurs speak Sylvan and Elven.</p>
    <h5>COMBAT</h5>
    <p className="initial">A centaur employing a lance deals double
damage when it charges, just as a rider on a mount does.</p>
    <h5>CENTAURS AS CHARACTERS</h5>
    <p className="initial">A centaur druid is usually a tribe&rsquo;s
designated leader and speaker. Centaur characters possess the following
racial traits. </p>
    <ul>
      <li>
        <p>+8 Strength, +4 Dexterity, +4 Constitution, &ndash;2
Intelligence,
+2 Wisdom.</p>
      </li>
      <li>
        <p>Large size. &ndash;1 penalty to Armor Class, &ndash;1
penalty on
attack
rolls, &ndash;4 penalty on Hide checks, +4 bonus on grapple checks,
lifting
and carrying limits double those of Medium characters.</p>
      </li>
      <li>
        <p>Space/Reach: 10 feet/5 feet.</p>
      </li>
      <li>
        <p>A centaur&rsquo;s base land speed is 50 feet.</p>
      </li>
      <li>
        <p>Darkvision out to 60 feet.</p>
      </li>
      <li>
        <p>Racial Hit Dice: A centaur begins with four levels of <a href="monsterTypes.html#monstrous-humanoid" style={{
            "color": "rgb(87, 158, 182)"
          }}>monstrous humanoid</a>, which
provide 4d8 Hit Dice, a base attack bonus of
+4, and base saving throw bonuses of Fort +1, Ref +4, and Will +4.</p>
      </li>
      <li>
        <p>Racial Skills: A centaur&rsquo;s monstrous humanoid levels
give
it
skill points equal to 7 x (2 + Int modifier). Its class skills are <a href="skillsAll.html#listen" style={{
            "color": "rgb(87, 158, 182)"
          }}>Listen</a>,
          <a href="skillsAll.html#move-silently" style={{
            "color": "rgb(87, 158, 182)"
          }}>Move
Silently</a>, <a href="skillsAll.html#spot" style={{
            "color": "rgb(87, 158, 182)"
          }}>Spot</a>, and <a href="skillsAll.html#survival" style={{
            "color": "rgb(87, 158, 182)"
          }}>Survival</a>.</p>
      </li>
      <li>
        <p>Racial Feats: A centaur&rsquo;s monstrous humanoid levels
give
it
two feats.</p>
      </li>
      <li>
        <p>+3 natural armor bonus.</p>
      </li>
      <li>
        <p>Automatic Languages: Sylvan, Elven. Bonus Languages:
Common,
Gnome, Halfling.</p>
      </li>
      <li>
        <p>Favored Class: <a href="ranger.html" style={{
            "color": "rgb(87, 158, 182)"
          }}>Ranger</a>.</p>
      </li>
      <li>
        <p>Level adjustment +2.</p>
      </li>
    </ul>
    <h3><a id="chaos-beast"></a>CHAOS BEAST</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG33.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Medium <a href="monsterTypes.html#outsider" style={{
        "color": "black"
      }}>Outsider</a> (<a href="monsterTypes.html#chaotic" style={{
        "color": "black"
      }}>Chaotic</a>, <a href="monsterTypes.html#extraplanar" style={{
        "color": "black"
      }}>Extraplanar</a>)</span>
    <span className="stat-block"><b>Hit Dice</b>: 8d8+8 (44 hp)</span> <span className="stat-block"><b>Initiative</b>: +5</span> <span className="stat-block"><b>Speed</b>: 20 ft. (4 squares)</span> <span className="stat-block"><b>Armor Class</b>: 16 (+1 Dex, +5
natural), touch 11, flat-footed 15</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +8/+10</span> <span className="stat-block"><b>Attack</b>:
Claw +10 melee (1d3+2
plus corporeal instability)</span> <span className="stat-block"><b>Full
Attack</b>: 2 claws +10 melee
(1d3+2 plus corporeal instability)</span> <span className="stat-block"><b>Space/Reach</b>:
5 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>:
Corporeal
instability</span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., immunity to critical hits and transformation, <a href="monsterTypes.html#SR" style={{
        "color": "rgb(87, 158, 182)"
      }}>spell
resistance</a> 15</span> <span className="stat-block"><b>Saves</b>: Fort
+7, Ref +7, Will +6</span> <span className="stat-block"><b>Abilities</b>:
Str 14, Dex 13, Con
13, Int 10, Wis 10, Cha 10</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
+13, <a href="skillsAll.html#escape-artist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Escape Artist</a>
+12, <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+12, <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
+9, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+11, <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
+11, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+11, <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a> +0
(+2 following tracks), <a href="skillsAll.html#tumble" style={{
        "color": "rgb(87, 158, 182)"
      }}>Tumble</a> +14, <a href="skillsAll.html#use-rope" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use
Rope</a> +1 (+3 with bindings)</span> <span className="stat-block"><b>Feats</b>:
      <a href="featsAll.html#dodge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Dodge</a>,
      <a href="featsAll.html#improved-initiative" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved
Initiative</a>, <a href="featsAll.html#mobility" style={{
        "color": "rgb(87, 158, 182)"
      }}>Mobility</a></span> <span className="stat-block"><b>Environment</b>:
Ever-Changing Chaos
of Limbo</span> <span className="stat-block"><b>Organization</b>: Solitary</span>
    <span className="stat-block"><b>Challenge Rating</b>: 7</span> <span className="stat-block"><b>Treasure</b>: None</span> <span className="stat-block"><b>Alignment</b>: Always chaotic neutral</span> <span className="stat-block"><b>Advancement</b>: 9&ndash;12 HD (Medium);
13&ndash;24 HD (Large)</span> <span className="stat-block"><b>Level
Adjustment</b>:
&mdash;</span>
    <p>The horrific creatures known as chaos beasts have mutable,
ever-changing forms. A chaos beast&rsquo;s dimensions vary, but it
always
weighs about 200 pounds.</p>
    <p>Chaos beasts do not speak.</p>
    <h5>COMBAT</h5>
    <p className="initial">For all its fearsome appearances, whether it
has claws, fangs, pincers, tentacles, or spines, a chaos beast does
little physical harm. Regardless of form, the creature seems unable to
manage more than two attacks per round. Its continual transmutations
prevent the coordination needed to do more.</p>
    <p>A chaos beast&rsquo;s claw attacks, as well as any weapons it
wields, are treated as chaotic-aligned for the purpose of overcoming <a href="monsterTypes.html#DR" style={{
        "color": "rgb(87, 158, 182)"
      }}>damage
reduction</a>.</p>
    <p><b>Corporeal Instability (Su)</b>: A blow from a chaos beast
against a living creature can cause a terrible transformation. The
creature must succeed on a DC 15 Fortitude save or become a spongy,
amorphous mass. Unless the victim manages to control the effect (see
below), its shape melts, flows, writhes, and boils. The save DC is
Constitution-based.</p>
    <p>An affected creature is unable to hold or use any item.
Clothing, armor, rings, and helmets become useless. Large items worn or
carried - armor, backpacks, even shirts - hamper more than help,
reducing
the victim&rsquo;s Dexterity score by 4. Soft or misshapen feet and
legs
reduce speed to 10 feet or one-quarter normal, whichever is less.
Searing pain courses along the nerves, so strong that the victim cannot
act coherently. The victim cannot cast spells or use magic items, and
it attacks blindly, unable to distinguish friend from foe (&ndash;4
penalty
on attack rolls and a 50% miss chance, regardless of the attack roll).</p>
    <p>Each round the victim spends in an amorphous state causes 1
point of Wisdom drain from mental shock. If the victim&rsquo;s Wisdom
score
falls to 0, it becomes a chaos beast.</p>
    <p>A victim can regain its own shape by taking a standard action
to attempt a DC 15 Charisma check (this check DC does not vary for a
chaos beast with different Hit Dice or ability scores). A success
reestablishes the creature&rsquo;s normal form for 1 minute. On a
failure,
the victim can still repeat this check each round until successful.</p>
    <p>Corporeal instability is not a disease or a curse and so is
hard to remove. A <a href="spellsS.html#shapechange" style={{
        "color": "rgb(87, 158, 182)"
      }}>shapechange</a> or <a href="spellsS.html#stoneskin" style={{
        "color": "rgb(87, 158, 182)"
      }}>stoneskin</a>
spell does not cure an
afflicted creature but fixes its form for the duration of the spell. A <a href="spellsPtoR.html#restoration" style={{
        "color": "rgb(87, 158, 182)"
      }}>restoration</a>,
      <a href="spellsHtoL.html#heal" style={{
        "color": "rgb(87, 158, 182)"
      }}>heal</a>,
or <a href="spellsPtoR.html#greater-restoration" style={{
        "color": "rgb(87, 158, 182)"
      }}>greater restoration</a> spell
removes the affliction
(a separate restoration is necessary to restore any drained points of
Wisdom).</p>
    <p><b>Immunity to Transformation (Ex)</b>: No mortal magic can
permanently affect or fix a chaos beast&rsquo;s form. Effects such as
polymorphing or <a href="abilitiesAndConditions.html#petrified" style={{
        "color": "rgb(87, 158, 182)"
      }}>petrification </a>force the
creature into a new shape, but
at the start of its next turn it immediately returns to its mutable
form as a free action.</p>
    <h3><a id="chimera"></a>CHIMERA</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG34.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Large <a href="monsterTypes.html#magical-beast" style={{
        "color": "black"
      }}>Magical Beast</a></span> <span className="stat-block"><b>Hit
Dice</b>: 9d10+27 (76 hp)</span> <span className="stat-block"><b>Initiative</b>:
+1</span> <span className="stat-block"><b>Speed</b>: 30 ft. (6 squares),
fly 50
ft. (poor)</span> <span className="stat-block"><b>Armor Class</b>: 19
(&ndash;1
size, +1 Dex,
+9 natural), touch 10, flat-footed 18</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +9/+17</span> <span className="stat-block"><b>Attack</b>:
Bite +12 melee (2d6+4)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +12 melee
(2d6+4) and bite +12 melee (1d8+4) and gore +12 melee (1d8+4) and 2
claws +10 melee (1d6+2)</span> <span className="stat-block"><b>Space/Reach</b>:
10 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#breath-weapon" style={{
        "color": "rgb(87, 158, 182)"
      }}>Breath weapon</a></span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., low-light vision, <a href="monsterTypes.html#scent" style={{
        "color": "rgb(87, 158, 182)"
      }}>scent</a></span> <span className="stat-block"><b>Saves</b>:
Fort +9, Ref +7, Will +6</span> <span className="stat-block"><b>Abilities</b>:
Str 19, Dex 13, Con
17, Int 4, Wis 13, Cha 10</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+1*, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+9, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+9</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="monsterFeats.html#hover" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hover</a>, <a href="featsAll.html#iron-will" style={{
        "color": "rgb(87, 158, 182)"
      }}>Iron
Will</a>, <a href="monsterFeats.html#multiattack" style={{
        "color": "rgb(87, 158, 182)"
      }}>Multiattack</a></span> <span className="stat-block"><b>Environment</b>:
Temperate hills</span> <span className="stat-block"><b>Organization</b>:
Solitary, pride
(3&ndash;5), or flight (6&ndash;13)</span> <span className="stat-block"><b>Challenge
Rating</b>: 7</span> <span className="stat-block"><b>Treasure</b>: Standard</span>
    <span className="stat-block"><b>Alignment</b>: Usually chaotic evil</span>
    <span className="stat-block"><b>Advancement</b>: 10&ndash;13 HD
(Large);
14&ndash;27 HD (Huge)</span> <span className="stat-block"><b>Level
Adjustment</b>:
+2 (cohort)</span>
    <p>A chimera is about 5 feet tall at the shoulder, nearly 10 feet
long, and weighs about 4,000 pounds. A chimera&rsquo;s dragon head
might be
black, blue, green, red, or white.</p>
    <p>Chimeras can speak Draconic but seldom bother to do so, except
when toadying to more powerful creatures.</p>
    <h5>COMBAT</h5>
    <p className="initial">The chimera prefers to surprise prey. It often
swoops down from the sky or lies concealed until it <a href="specialAttacks.html#charge" style={{
        "color": "rgb(87, 158, 182)"
      }}>charges</a>.
The dragon
head can loose a <a href="monsterTypes.html#breath-weapon" style={{
        "color": "rgb(87, 158, 182)"
      }}>breath weapon</a> instead of biting.
Several chimeras
attack in concert.</p>
    <p><b>Breath Weapon (Su)</b>: A chimera&rsquo;s <a href="monsterTypes.html#breath-weapon" style={{
        "color": "rgb(87, 158, 182)"
      }}>breath weapon</a> depends
on the color of its dragon head, as summarized on the table below.
Regardless of its type, a chimera&rsquo;s breath weapon is usable once
every
1d4 rounds, deals 3d8 points of damage, and allows a DC 17 Reflex save
for half damage. The save DC is Constitution-based.</p>
    <p>To determine a chimera&rsquo;s head color and breath weapon
randomly, roll 1d10 and consult the table below.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "75px"
          }}>1d10</th>
          <th style={{
            "width": "100px"
          }}>Head Color</th>
          <th style={{
            "width": "175px"
          }}>Breath Weapon</th>
        </tr>
        <tr className="odd-row">
          <td>1&ndash;2</td>
          <td>Black</td>
          <td>40-foot line of acid</td>
        </tr>
        <tr>
          <td>3&ndash;4</td>
          <td>Blue</td>
          <td>40-foot line of lightning</td>
        </tr>
        <tr className="odd-row">
          <td>5&ndash;6</td>
          <td>Green</td>
          <td>20-foot cone of gas (acid)</td>
        </tr>
        <tr>
          <td>7&ndash;8</td>
          <td>Red</td>
          <td>20-foot cone of fire</td>
        </tr>
        <tr className="odd-row">
          <td>9&ndash;10</td>
          <td>White</td>
          <td>20-foot cone of cold</td>
        </tr>
      </tbody>
    </table>
    <p><b>Skills</b>: A chimera&rsquo;s three heads give it a +2
racial
bonus on <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> and <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
checks.</p>
    <p>*In areas of scrubland or brush, a chimera gains a +4 racial
bonus on <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a> checks.</p>
    <p><b>Carrying Capacity</b>: A light load for a chimera is up to
348 pounds; a medium load, 349&ndash;699 pounds, and a heavy load,
700&ndash;1,050
pounds.</p>
    <h3><a id="choker"></a>CHOKER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG35a.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Small <a href="monsterTypes.html#aberration" style={{
        "color": "black"
      }}>Aberration</a></span> <span className="stat-block"><b>Hit
Dice</b>: 3d8+3 (16 hp)</span> <span className="stat-block"><b>Initiative</b>:
+6</span> <span className="stat-block"><b>Speed</b>: 20 ft. (4 squares),
climb
10 ft.</span> <span className="stat-block"><b>Armor Class</b>: 17 (+1
size, +2 Dex,
+4 natural), touch 13, flat-footed 15</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +2/+5</span> <span className="stat-block"><b>Attack</b>:
Tentacle +6 melee (1d3+3)</span> <span className="stat-block"><b>Full
Attack</b>: 2 tentacles +6 melee
(1d3+3)</span> <span className="stat-block"><b>Space/Reach</b>: 5 ft./10
ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#improved-grab" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved
grab</a>, <a href="monsterTypes.html#constrict" style={{
        "color": "rgb(87, 158, 182)"
      }}>constrict</a> 1d3+3</span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., quickness</span> <span className="stat-block"><b>Saves</b>: Fort +2,
Ref +5, Will +4</span> <span className="stat-block"><b>Abilities</b>: Str
16, Dex 14, Con
13, Int 4, Wis 13, Cha 7</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
+13, <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+10, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move
Silently</a> +6</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#improved-initiative" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved
Initiative<sup>B</sup></a>, <a href="featsAll.html#lightning-reflexes" style={{
        "color": "rgb(87, 158, 182)"
      }}>Lightning Reflexes</a>, <a href="featsAll.html#stealthy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Stealthy</a></span>
    <span className="stat-block"><b>Environment</b>:
Underground</span> <span className="stat-block"><b>Organization</b>:
Solitary</span> <span className="stat-block"><b>Challenge Rating</b>: 2</span>
    <span className="stat-block"><b>Treasure</b>: 1/10 coins; 50% goods;
50% items</span> <span className="stat-block"><b>Alignment</b>: Usually
chaotic evil</span> <span className="stat-block"><b>Advancement</b>:
4&ndash;6
HD (Small); 7&ndash;12
HD (Medium)</span> <span className="stat-block"><b>Level Adjustment</b>:
&mdash;</span>
    <p>These vicious little predators lurk underground, grabbing
whatever prey happens by. Its hands and feet have spiny pads that help
the choker grip almost any surface. The creature weighs about 35 pounds.</p>
    <p>Chokers speak Undercommon.</p>
    <h5>COMBAT</h5>
    <p className="initial">A choker likes to perch near the ceiling,
often at intersections, archways, wells, or staircases, and reach down
to attack its prey. A choker attacks creatures of almost any size, but
prefers lone prey of its size or larger. If one is very hungry, it may
attack a group, but it waits to <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grab</a> the last creature in line.</p>
    <p><b>Constrict (Ex)</b>: A choker deals 1d3+3 points of damage
with a successful <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a> check against a Large or
smaller creature.
Because it seizes its victim by the neck, a creature in the
choker&rsquo;s
grasp cannot speak or cast spells with verbal components.</p>
    <p><b>Improved Grab (Ex)</b>: To use this ability, a choker must
hit a Large or smaller opponent with a tentacle attack. It can then
attempt to start a <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a> as a free action without
provoking an attack
of opportunity. If it wins the grapple check, it establishes a hold and
can constrict. Chokers receive a +4 racial bonus on grapple checks,
which is already included in the statistics block.</p>
    <p><b>Quickness (Su)</b>: Although not particularly dexterous, a
choker is supernaturally quick. It can take an extra standard action or
move action during its turn each round.</p>
    <p><b>Skills</b>: A chokers has a +8 racial bonus on <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
checks
and can always choose to take 10 on Climb checks, even if rushed or
threatened.</p>
    <h3><a id="chuul"></a>CHUUL</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG35b.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Large <a href="monsterTypes.html#aberration" style={{
        "color": "black"
      }}>Aberration</a> (<a href="monsterTypes.html#aquatic" style={{
        "color": "black"
      }}>Aquatic</a>)</span>
    <span className="stat-block"><b>Hit Dice</b>: 11d8+44 (93 hp)</span> <span className="stat-block"><b>Initiative</b>: +7</span> <span className="stat-block"><b>Speed</b>: 30 ft. (6 squares), swim
20 ft.</span> <span className="stat-block"><b>Armor Class</b>: 22
(&ndash;1
size, +3 Dex,
+10 natural), touch 12, flat-footed 19</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +8/+17</span> <span className="stat-block"><b>Attack</b>:
Claw +12 melee (2d6+5)</span> <span className="stat-block"><b>Full Attack</b>:
2 claws +12 melee
(2d6+5)</span> <span className="stat-block"><b>Space/Reach</b>: 10 ft./5
ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#constrict" style={{
        "color": "rgb(87, 158, 182)"
      }}>Constrict</a>
3d6+5, <a href="monsterTypes.html#improved-grab" style={{
        "color": "rgb(87, 158, 182)"
      }}>improved grab</a>, paralytic
tentacles</span> <span className="stat-block"><b>Special
Qualities</b>: Amphibious,
darkvision 60 ft., immunity to poison</span> <span className="stat-block"><b>Saves</b>:
Fort +7, Ref +6, Will +9</span> <span className="stat-block"><b>Abilities</b>:
Str 20, Dex 16, Con
18, Int 10, Wis 14, Cha 5</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+13, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+11, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+11, <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
+13</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#blind-fight" style={{
        "color": "rgb(87, 158, 182)"
      }}>Blind-Fight</a>, <a href="featsAll.html#combat-reflexes" style={{
        "color": "rgb(87, 158, 182)"
      }}>Combat
Reflexes</a>, <a href="featsAll.html#improved-initiative" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved Initiative</a></span> <span className="stat-block"><b>Environment</b>: Temperate marshes</span> <span className="stat-block"><b>Organization</b>: Solitary, pair, or
pack (3&ndash;5)</span> <span className="stat-block"><b>Challenge Rating</b>:
7</span> <span className="stat-block"><b>Treasure</b>: 1/10th coins; 50%
goods; standard items</span> <span className="stat-block"><b>Alignment</b>:
Usually chaotic evil</span> <span className="stat-block"><b>Advancement</b>:
12&ndash;16 HD (Large);
17&ndash;33 HD (Huge)</span> <span className="stat-block"><b>Level
Adjustment</b>:
&mdash;</span>
    <p>A horrible mix of crustacean, insect, and serpent, the chuul
is an abomination that lurks submerged or partially submerged, awaiting
intelligent prey to devour. Although amphibious, chuuls are not good
swimmers and actually prefer to be on land or in very shallow water
when they attack. A chuul is about 8 feet long and weighs 650 pounds.</p>
    <p>Chuuls speak Common (or Undercommon, for the underground
variety).</p>
    <h5>COMBAT</h5>
    <p className="initial">A chuul prefers to wait by the shore,
submerged in murky water, until it hears nearby prey (in or out of the
water) that it can attack with surprise. A chuul <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grabs</a>
with its claws
and <a href="monsterTypes.html#constrict" style={{
        "color": "rgb(87, 158, 182)"
      }}>constricts</a> its foe, then passes
the opponent to its paralytic
tentacles. It tries to always have one claw free, so if it faces a
large number of opponents, it drops a <a href="abilitiesAndConditions.html#paralyzed" style={{
        "color": "rgb(87, 158, 182)"
      }}>paralyzed</a> or <a href="abilitiesAndConditions.html#dead" style={{
        "color": "rgb(87, 158, 182)"
      }}>dead</a> victim and
continues attempting to grab, constrict, and paralyze the rest.</p>
    <p><b>Constrict (Ex)</b>: On a successful <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a>
check, a chuul
deals 3d6+5 points of damage.</p>
    <p><b>Improved Grab (Ex)</b>: To use this ability, a chuul must
hit with a claw attack. It can then attempt to start a <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a>
as a
free action without provoking an attack of opportunity. If it wins the
grapple check, it establishes a hold and can constrict or on its next
turn transfer a grabbed opponent to its tentacles.</p>
    <p><b>Paralytic Tentacles (Ex)</b>: A chuul can transfer grabbed
victims from a claw to its tentacles as a move action. The tentacles <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a>
with the same strength
as the claw but deal no damage. However,
they exude a paralytic secretion. Anyone held in the tentacles must
succeed on a DC 19 Fortitude save each round on the chuul&rsquo;s turn
or be <a href="abilitiesAndConditions.html#paralyzed" style={{
        "color": "rgb(87, 158, 182)"
      }}>paralyzed</a> for 6 rounds. The save
DC is Constitution-based. While held
in the tentacles, paralyzed or not, a victim automatically takes 1d8+2
points of damage each round from the creature&rsquo;s mandibles.</p>
    <p><b>Amphibious (Ex)</b>: Although chuuls are aquatic, they can
survive indefinitely on land.</p>
    <p><b>Skills</b>: A chuul has a +8 racial bonus on any <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
check
to perform some special action or avoid a hazard. It can always choose
to take 10 on a Swim check, even if distracted or endangered. It can
use the run action while swimming, provided it swims in a straight line.</p>
    <h3><a id="cloaker"></a>CLOAKER</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG36.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Large <a style={{
        "color": "black"
      }} href="monsterTypes.html#aberration">Aberration</a></span> <span className="stat-block"><b>Hit
Dice</b>: 6d8+18 (45 hp)</span> <span className="stat-block"><b>Initiative</b>:
+7</span> <span className="stat-block"><b>Speed</b>: 10 ft. (2 squares),
fly 40
ft. (average)</span> <span className="stat-block"><b>Armor Class</b>: 19
(&ndash;1 size, +3 Dex,
+7 natural), touch 12, flat-footed 16</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +4/+13 </span> <span className="stat-block"><b>Attack</b>:
Tail slap +8 melee (1d6+5)</span> <span className="stat-block"><b>Full
Attack</b>: Tail slap +8 melee
(1d6+5) and bite +3 melee (1d4+2)</span> <span className="stat-block"><b>Space/Reach</b>:
10 ft./10 ft. (5 ft.
with bite)</span> <span className="stat-block"><b>Special Attacks</b>:
Moan, engulf</span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., shadow shift</span> <span className="stat-block"><b>Saves</b>: Fort
+5, Ref +5, Will +7</span> <span className="stat-block"><b>Abilities</b>:
Str 21, Dex 16, Con
17, Int 14, Wis 15, Cha 15</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a>
+8, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+13, <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move
Silently</a> +12, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> +13</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>, <a href="featsAll.html#combat-reflexes" style={{
        "color": "rgb(87, 158, 182)"
      }}>Combat
Reflexes</a>, <a href="featsAll.html#improved-initiative" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved Initiative</a></span> <span className="stat-block"><b>Environment</b>:
Underground</span> <span className="stat-block"><b>Organization</b>:
Solitary, mob
(3&ndash;6), or flock (7&ndash;12)</span> <span className="stat-block"><b>Challenge
Rating</b>: 5</span> <span className="stat-block"><b>Treasure</b>: Standard</span>
    <span className="stat-block"><b>Alignment</b>: Usually chaotic neutral</span>
    <span className="stat-block"><b>Advancement</b>: 7&ndash;9 HD
(Large);
10&ndash;18 HD (Huge)</span> <span className="stat-block"><b>Level
Adjustment</b>:
&mdash;</span>
    <p>When resting or lying in wait, these creatures are almost
impossible to distinguish from common black cloaks (the cloaker&rsquo;s
ivory
claws look very much like bone clasps). Only when it unfurls does the
horrific nature of the creature become apparent.</p>
    <p>A cloaker has a wingspan of about 8 feet. It weighs about 100
pounds.</p>
    <p>Cloakers speak Undercommon.</p>
    <h5>COMBAT</h5>
    <p className="initial">Cloakers usually lie still, watching and
listening for prey. If facing a single opponent, a cloaker uses its
engulf attack. Against multiple foes, it lashes with its tail in
concert with its moan and shadow shift abilities to reduce the
opposition&rsquo;s numbers, then engulfs a survivor. Multiple cloakers
usually split up, leaving one or two behind to use special abilities
while the rest make melee attacks.</p>
    <p><b>Moan (Ex)</b>: A cloaker can emit a dangerous subsonic moan
as a standard action. By changing the frequency, the cloaker can cause
one of four effects. Cloakers are immune to these sonic, mindaffecting
attacks. Unless otherwise specified, a creature that successfully saves
against one of these effects cannot be affected by the same moan effect
from the same cloaker for 24 hours. All save DCs for moan effects are
Charisma-based.</p>
    <p><i>Unnerve</i>: Anyone within a 60-foot spread automatically
takes a &ndash;2 penalty on attack and damage rolls. Those forced to
hear the
moan for more than 6 consecutive rounds must succeed on a DC 15 Will
save or enter a trance, unable to attack or defend themselves until the
moaning stops.</p>
    <p><i>Fear</i>: Anyone within a 30-foot spread must succeed on a
DC 15 Will save or become <a href="abilitiesAndConditions.html#panicked" style={{
        "color": "rgb(87, 158, 182)"
      }}>panicked</a> for 2 rounds.</p>
    <p><i>Nausea</i>: Anyone in a 30-foot cone must succeed on a DC
15 Fortitude save or be overcome by nausea and weakness. Affected
characters fall <a href="abilitiesAndConditions.html#prone" style={{
        "color": "rgb(87, 158, 182)"
      }}>prone</a> and become <a href="abilitiesAndConditions.html#nauseated" style={{
        "color": "rgb(87, 158, 182)"
      }}>nauseated</a> for 1d4+1 rounds.</p>
    <p><i>Stupor</i>: A single creature within 30 feet of the cloaker
must succeed on a DC 15 Fortitude save or be affected as though by a <a href="spellsHtoL.html#hold-monster" style={{
        "color": "rgb(87, 158, 182)"
      }}>hold
monster</a> spell for 5 rounds.
Even after a successful save, the
creature must repeat the save if the cloaker uses this effect again.</p>
    <p><b>Engulf (Ex)</b>: A cloaker can try to wrap a Medium or
smaller creature in its body as a standard action. The cloaker attempts
a <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a> that does not provoke an
attack of opportunity. If it wins
the grapple check, it establishes a hold and bites the engulfed victim
with a +4 bonus on its attack roll. It can still use its whiplike tail
to strike at other targets.</p>
    <p>Attacks that hit an engulfing cloaker deal half their damage
to the monster and half to the trapped victim.</p>
    <p><b>Shadow Shift (Su)</b>: A cloaker can manipulate shadows.
This ability is effective only in shadowy areas and has three possible
effects.</p>
    <p><i>Obscure Vision</i>: The cloaker gains concealment (20% miss
chance) for 1d4 rounds.</p>
    <p><i>Dancing Images</i>: This effect duplicates a <a href="spellsMtoO.html#mirror-image" style={{
        "color": "rgb(87, 158, 182)"
      }}>mirror
image</a>
spell (caster level 6th).</p>
    <p><i>Silent Image</i>: This effect duplicates a <a href="spellsS.html#silent-image" style={{
        "color": "rgb(87, 158, 182)"
      }}>silent
image</a>
spell (DC 15, caster level 6th). The save DC is Charisma-based.</p>
    <h3><a id="cockatrice"></a>COCKATRICE</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG37.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Small <a href="monsterTypes.html#magical-beast" style={{
        "color": "black"
      }}>Magical Beast</a></span> <span className="stat-block"><b>Hit
Dice</b>: 5d10 (27 hp)</span> <span className="stat-block"><b>Initiative</b>:
+3</span> <span className="stat-block"><b>Speed</b>: 20 ft. (4 squares),
fly 60
ft. (poor)</span> <span className="stat-block"><b>Armor Class</b>: 14 (+1
size, +3
Dex), touch 14, flat-footed 11</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +5/&ndash;1</span> <span className="stat-block"><b>Attack</b>:
Bite +9 melee (1d4&ndash;2 plus
petrification)</span> <span className="stat-block"><b>Full Attack</b>:
Bite +9 melee (1d4&ndash;2
plus petrification)</span> <span className="stat-block"><b>Space/Reach</b>:
5 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="abilitiesAndConditions.html#petrified" style={{
        "color": "rgb(87, 158, 182)"
      }}>Petrification</a></span> <span className="stat-block"><b>Special Qualities</b>:
Darkvision 60
ft., low-light vision</span> <span className="stat-block"><b>Saves</b>:
Fort +4, Ref +7, Will +2</span> <span className="stat-block"><b>Abilities</b>:
Str 6, Dex 17, Con 11,
Int 2, Wis 13, Cha 9</span> <span className="stat-block"><b>Skills</b>: <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+7, <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
+7</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#alertness" style={{
        "color": "rgb(87, 158, 182)"
      }}>Alertness</a>,
      <a href="featsAll.html#dodge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Dodge</a>,
      <a href="featsAll.html#weapon-finesse" style={{
        "color": "rgb(87, 158, 182)"
      }}>Weapon
Finesse<sup>B</sup></a></span> <span className="stat-block"><b>Environment</b>:
Temperate
plains</span> <span className="stat-block"><b>Organization</b>: Solitary,
pair,
flight (3&ndash;5), or flock (6&ndash;13)</span> <span className="stat-block"><b>Challenge
Rating</b>: 3</span> <span className="stat-block"><b>Treasure</b>: None</span>
    <span className="stat-block"><b>Alignment</b>: Always neutral</span> <span className="stat-block"><b>Advancement</b>: 6&ndash;8 HD (Small);
9&ndash;15
HD (Medium)</span> <span className="stat-block"><b>Level Adjustment</b>:
&mdash;</span>
    <p>A male cockatrice has wattles and a comb, just like a rooster.
Females, much rarer than males, differ only in that they have no
wattles or comb. A cockatrice weighs about 25 pounds.</p>
    <h5>COMBAT</h5>
    <p className="initial">A cockatrice fiercely attacks anything that it
deems a threat to itself or its lair. Flocks of cockatrices do their
utmost to overwhelm and confuse their foes, and sometimes fly directly
into their opponents&rsquo; faces.</p>
    <p><b>Petrification (Su)</b>: Creatures hit by a
cockatrice&rsquo;s
bite attack must succeed on a DC 12 Fortitude save or instantly <a href="abilitiesAndConditions.html#petrified" style={{
        "color": "rgb(87, 158, 182)"
      }}>turn to
stone</a>. The save DC is Constitution-based. Cockatrices have immunity
to
the petrification ability of other cockatrices, but other petrification
attacks affect them normally.</p>
    <h3><a id="couatl"></a>COUATL</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <a href="http://www.wizards.com/dnd/images/MM35_gallery/MM35_PG38a.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
        "border": "0px solid",
        "width": "73px",
        "height": "75px"
      }} /></a><span className="size-block">Large <a href="monsterTypes.html#outsider" style={{
        "color": "black"
      }}>Outsider</a> (<a href="monsterTypes.html#native" style={{
        "color": "black"
      }}>Native</a>)</span> <span className="stat-block"><b>Hit
Dice</b>: 9d8+18 (58 hp)</span> <span className="stat-block"><b>Initiative</b>:
+7</span> <span className="stat-block"><b>Speed</b>: 20 ft. (4 squares),
fly 60
ft. (good)</span> <span className="stat-block"><b>Armor Class</b>: 21
(&ndash;1
size, +3 Dex,
+9 natural), touch 12, flat-footed 18</span> <span className="stat-block"><b>Base
Attack/Grapple</b>: +9/+17</span> <span className="stat-block"><b>Attack</b>:
Bite +12 melee (1d3+6
plus poison)</span> <span className="stat-block"><b>Full Attack</b>: Bite
+12 melee
(1d3+6 plus poison)</span> <span className="stat-block"><b>Space/Reach</b>:
10 ft./5 ft.</span> <span className="stat-block"><b>Special Attacks</b>: <a href="monsterTypes.html#constrict" style={{
        "color": "rgb(87, 158, 182)"
      }}>Constrict</a>
2d8+6, <a href="monsterTypes.html#improved-grab" style={{
        "color": "rgb(87, 158, 182)"
      }}>improved grab</a>, <a href="monsterTypes.html#poison" style={{
        "color": "rgb(87, 158, 182)"
      }}>poison</a>,
      <a href="monsterTypes.html#psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>psionics</a>, <a href="monsterTypes.html#spells" style={{
        "color": "rgb(87, 158, 182)"
      }}>spells</a></span>
    <span className="stat-block"><b>Special
Qualities</b>: Darkvision 60
ft., <a href="spellsDtoE.html#ethereal-jaunt" style={{
        "color": "rgb(87, 158, 182)"
      }}>ethereal jaunt</a>, <a href="monsterTypes.html#telepathy" style={{
        "color": "rgb(87, 158, 182)"
      }}>telepathy</a>
90 ft.</span> <span className="stat-block"><b>Saves</b>:
Fort +8, Ref +9, Will +10</span> <span className="stat-block"><b>Abilities</b>:
Str 18, Dex 16, Con
14, Int 17, Wis 19, Cha 17</span> <span className="stat-block"><b>Skills</b>:
      <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> +14, <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a>
+17, <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
+0, <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> (any two) +15, <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a>
+16, <a href="skillsAll.html#search" style={{
        "color": "rgb(87, 158, 182)"
      }}>Search</a>
+15, <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a> +16, <a href="skillsAll.html#spellcraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spellcraft</a>
+15 (+17 scrolls), <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a> +16, <a href="skillsAll.html#survival" style={{
        "color": "rgb(87, 158, 182)"
      }}>Survival</a>
+4 (+6 following tracks), <a href="skillsAll.html#tumble" style={{
        "color": "rgb(87, 158, 182)"
      }}>Tumble</a> +15, <a href="skillsAll.html#use-magic-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use Magic Device</a> +15 (+17
scrolls)</span> <span className="stat-block"><b>Feats</b>: <a href="featsAll.html#dodge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Dodge</a>,
      <a href="featsAll.html#empower-spell" style={{
        "color": "rgb(87, 158, 182)"
      }}>Empower
Spell</a>, <a href="featsAll.html#eschew-materials" style={{
        "color": "rgb(87, 158, 182)"
      }}>Eschew Materials<sup>B</sup></a>, <a href="monsterFeats.html#hover" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hover</a>,
      <a href="featsAll.html#improved-initiative" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved Initiative</a></span> <span className="stat-block"><b>Environment</b>: Warm forests</span> <span className="stat-block"><b>Organization</b>: Solitary, pair, or
flight (3&ndash;6)</span> <span className="stat-block"><b>Challenge Rating</b>:
10</span> <span className="stat-block"><b>Treasure</b>: Standard</span> <span className="stat-block"><b>Alignment</b>: Always lawful good</span> <span className="stat-block"><b>Advancement</b>: 10&ndash;13 HD (Large);
14&ndash;27 HD (Huge)</span> <span className="stat-block"><b>Level
Adjustment</b>:
+7</span>
    <p>A couatl is about 12 feet long, with a wingspan of about 15
feet. It weighs about 1,800 pounds.</p>
    <p>Couatls speak Celestial, Common, and Draconic, and also have
the power of telepathy (see below).</p>
    <h5>COMBAT</h5>
    <p className="initial">A couatl uses its <a href="spellsDtoE.html#detect-thoughts" style={{
        "color": "rgb(87, 158, 182)"
      }}>detect thoughts</a> ability on
any creature that arouses its suspicions. Since it is highly
intelligent, a couatl usually casts spells from a distance before
closing. If more than one couatl is involved, they discuss their
strategy before a battle.</p>
    <p><b>Change Shape (Su):</b> A couatl can assume the form of
any Small or Medium humanoid.</p>
    <p><b>Constrict (Ex)</b>: A couatl deals 2d8+6 points of damage
with a successful <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a> check.</p>
    <p><b>Improved Grab (Ex)</b>: To use this ability, a couatl must
hit a creature of up to two size categories larger with its bite
attack. It can then attempt to start a <a href="specialAttacks.html#grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>grapple</a>
as a free action without
provoking an attack of opportunity. If it wins the grapple check, it
establishes a hold and can constrict.</p>
    <p><b>Poison (Ex)</b>: Injury, Fortitude DC 16, initial damage
2d4 Str, secondary damage 4d4 Str. The save DC is Constitution-based.</p>
    <p><b>Psionics (Sp)</b>: Effective caster level 9th. The
save DCs are Charisma-based. 
    </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>At will</span>&mdash;<a href="spellsDtoE.html#detect-chaos" style={{
        "color": "rgb(87, 158, 182)"
      }}>detect
chaos</a>, <a href="spellsDtoE.html#detect-evil" style={{
        "color": "rgb(87, 158, 182)"
      }}>detect evil</a>, <a href="spellsDtoE.html#detect-good" style={{
        "color": "rgb(87, 158, 182)"
      }}>detect
good</a>, <a href="spellsDtoE.html#detect-law" style={{
        "color": "rgb(87, 158, 182)"
      }}>detect
law</a>, <a href="spellsDtoE.html#detect-thoughts" style={{
        "color": "rgb(87, 158, 182)"
      }}>detect thoughts</a> (DC 15), <a href="spellsHtoL.html#invisibility" style={{
        "color": "rgb(87, 158, 182)"
      }}>invisibility</a>,
      <a href="spellsPtoR.html#planeshift" style={{
        "color": "rgb(87, 158, 182)"
      }}>plane
shift</a> (DC 20)</p>
    <p><b>Spells</b>: A couatl casts spells as a 9th-level sorcerer.
It can choose its spells known from the <a href="wizardSpells.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>sorcerer list</a>, the <a href="clericSpells.html" style={{
        "color": "rgb(87, 158, 182)"
      }}>cleric list</a>,
and from the lists for the <a href="clericDomains.html#air" style={{
        "color": "rgb(87, 158, 182)"
      }}>Air</a>, <a href="clericDomains.html#good" style={{
        "color": "rgb(87, 158, 182)"
      }}>Good</a>,
and <a href="clericDomains.html#law" style={{
        "color": "rgb(87, 158, 182)"
      }}>Law</a>
domains. The cleric
spells and domain spells are considered arcane spells for a couatl,
meaning that the creature does not need a divine focus to cast them.</p>
    <i>Typical Spells Known (6/7/7/7/4; save DC 13 + spell level)</i>:
    <span style={{
      "fontStyle": "italic"
    }}>0</span> - <a href="spellsC.html#cure-minor-wounds" style={{
      "color": "rgb(87, 158, 182)"
    }}>cure
minor wounds</a>, <a href="spellsDtoE.html#daze" style={{
      "color": "rgb(87, 158, 182)"
    }}>daze</a>, <a href="spellsDtoE.html#disrupt-undead" style={{
      "color": "rgb(87, 158, 182)"
    }}>disrupt
undead</a>, <a href="spellsHtoL.html#light" style={{
      "color": "rgb(87, 158, 182)"
    }}>light</a>, <a href="spellsMtoO.html#obscuring-mist" style={{
      "color": "rgb(87, 158, 182)"
    }}>obscuring
mist</a>, <a href="spellsPtoR.html#read-magic" style={{
      "color": "rgb(87, 158, 182)"
    }}>ray
of frost</a>, <a href="spellsPtoR.html#read-magic" style={{
      "color": "rgb(87, 158, 182)"
    }}>read magic</a>, <a href="spellsPtoR.html#resistance" style={{
      "color": "rgb(87, 158, 182)"
    }}>resistance</a>;
    <p><span style={{
        "fontStyle": "italic"
      }}>1st</span> - <a href="spellsDtoE.html#endure-elements" style={{
        "color": "rgb(87, 158, 182)"
      }}>endure elements</a>, <a href="spellsMtoO.html#mage-armor" style={{
        "color": "rgb(87, 158, 182)"
      }}>mage
armor</a>, <a href="spellsPtoR.html#protection-from-chaos" style={{
        "color": "rgb(87, 158, 182)"
      }}>protection from chaos</a>, <a href="spellsTtoZ.html#true-strike" style={{
        "color": "rgb(87, 158, 182)"
      }}>true
strike</a>, <a href="spellsTtoZ.html#wind-wall" style={{
        "color": "rgb(87, 158, 182)"
      }}>wind wall</a>; </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>2nd - </span><a href="spellsC.html#cure-moderate-wounds" style={{
        "color": "rgb(87, 158, 182)"
      }}>cure moderate
wounds</a>, <a href="spellsDtoE.html#eagles-splendor" style={{
        "color": "rgb(87, 158, 182)"
      }}>eagle&rsquo;s splendor</a>, <a href="spellsS.html#scorching-ray" style={{
        "color": "rgb(87, 158, 182)"
      }}>scorching
ray</a>, <a href="spellsS.html#silence" style={{
        "color": "rgb(87, 158, 182)"
      }}>silence</a>; </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>3rd</span> - <a href="spellsFtoG.html#gaseous-form" style={{
        "color": "rgb(87, 158, 182)"
      }}>gaseous
form</a>, <a href="spellsMtoO.html#magic-circle-against-evil" style={{
        "color": "rgb(87, 158, 182)"
      }}>magic circle against evil</a>, <a href="spellsS.html#summon-monster-iii" style={{
        "color": "rgb(87, 158, 182)"
      }}>summon monster III</a>; </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>4th</span> - <a href="spellsC.html#charm-monster" style={{
        "color": "rgb(87, 158, 182)"
      }}>charm
monster</a>, <a href="spellsFtoG.html#freedom-of-movement" style={{
        "color": "rgb(87, 158, 182)"
      }}>freedom of movement</a>.</p>
    <p><b>Ethereal Jaunt (Su)</b>: This ability works like the <a href="spellsDtoE.html#ethereal-jaunt" style={{
        "color": "rgb(87, 158, 182)"
      }}>ethereal
jaunt</a> spell (caster
level 16th).</p>
    <p><b>Telepathy (Su)</b>: A couatl can communicate telepathically
with any creature within 90 feet that has an Intelligence score. The
creature can respond to the couatl if it wishes - no common language is
needed.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      